import { useState } from "react";
import requestToServer from "utils/requestToServer";

const useLoadPharmacies = (path, { onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);

  const loadPharmacies = (searchParams) => {
    setLoading(true);
    requestToServer({
      path: `${path}?${new URLSearchParams(searchParams)}`,
    })
      .then((response) => response.json())
      .then(setPharmacies)
      .then(() => setLoading(false))
      .then(onCompleted);
  };

  return { pharmacies, loading, loadPharmacies };
};

export default useLoadPharmacies;
