import * as Yup from "yup";
import initTranslationsHelper from "utils/initTranslationsHelper";

const t = initTranslationsHelper("shared.validation");

const getValidationSchema = (patientOfEmancipationAge) => {
  const fields = {
    maritalStatus: Yup.string().nullable(),
    preferredName: Yup.string().nullable(),
    ssn: Yup.string().length(9).nullable(),
  };

  if (patientOfEmancipationAge) {
    fields.maritalStatus = Yup.string().required(t("error_one_of"));
  }

  return Yup.object(fields);
};

export default getValidationSchema;
