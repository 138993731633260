import Bold from "components/Typography/Bold";
import Page from "components/Page";
import FullscreenSpinner from "components/FullscreenSpinner";
import t from "./translate";
import PaginatedList from "./PaginatedList";

export const RESULTS_PER_PAGE = 6;

const SearchResults = ({
  cantFindButtonText,
  confirmQuestion,
  loading,
  noResultsHint,
  onEdit,
  onNotFound,
  onSelect,
  results,
  searchParams,
  title,
  paginationEnabled,
}) => (
  <>
    <Page>
      <Page.Title>{title}</Page.Title>
      <Page.Subtitle>
        {results.length ? t("results_found", { count: results.length }) : t("no_results_found")}
        <Bold>{searchParams.title}</Bold>
        {t("in")}
        <Bold>{searchParams.zip ? searchParams.zip : `${searchParams.city}, ${searchParams.state}`}</Bold>
      </Page.Subtitle>
      <Page.Layout />
      <Page.Content>
        <PaginatedList
          confirmQuestion={confirmQuestion}
          loading={loading}
          noResultsHint={noResultsHint}
          onSelect={onSelect}
          paginationEnabled={paginationEnabled}
          results={results}
        />
      </Page.Content>
      <Page.ButtonPrimary onClick={onEdit} variant={paginationEnabled ? "contained" : "outlined"}>
        {t("edit_search")}
      </Page.ButtonPrimary>
      {paginationEnabled ? (
        <Page.ButtonSecondary onClick={onNotFound}>{cantFindButtonText}</Page.ButtonSecondary>
      ) : (
        <Page.ButtonTertiary onClick={onNotFound}>{cantFindButtonText}</Page.ButtonTertiary>
      )}
    </Page>
    <FullscreenSpinner visible={loading} />
  </>
);

export default SearchResults;
