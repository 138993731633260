import { Grid, Typography } from "@mui/material";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikPhoneField from "components/FormikControls/FormikPhoneField";
import t from "../translate";

const InfoFields = ({ toggle, monthOptions, yearOptions, initialMonth, initialDay, initialYear }) => {
  if (!toggle) {
    return null;
  }

  return (
    <>
      <Grid item xs={6}>
        <FormikTextField fullWidth label={t("guarantorfirstname")} name="firstName" required />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField fullWidth label={t("guarantorlastname")} name="lastName" required />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">{t("guarantordob")}</Typography>
      </Grid>
      <Grid item xs={4}>
        <FormikSelect
          label={t("guarantor_month")}
          name="month"
          options={monthOptions}
          required
          valueOnInitialInteraction={initialMonth}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikSelect
          label={t("guarantor_day")}
          name="day"
          options={Array.from({ length: 31 }, (_, i) => i + 1)}
          required
          valueOnInitialInteraction={initialDay}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikSelect
          label={t("guarantor_year")}
          name="year"
          options={yearOptions}
          required
          valueOnInitialInteraction={initialYear}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikPhoneField fullWidth label={t("guarantorphone")} name="phone" required />
      </Grid>
    </>
  );
};

export default InfoFields;
