import initFBSelect from "./fb_select";
import initFBTextEditor from "./fb_text_editor";
import initFBDivider from "./fb_divider";
import initFBPainDiagram from "./fb_pain_diagram";

function initFormBuilderControls() {
  initFBTextEditor();
  initFBDivider();
  initFBSelect();
  initFBPainDiagram();
}

export default initFormBuilderControls;
