import Page from "components/Page";
import { Formik } from "formik";
import FormikTextField from "components/FormikControls/FormikTextField";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import FullscreenSpinner from "components/FullscreenSpinner";
import OneColumnGrid from "components/OneColumnGrid";
import FormikErrorTracker from "components/FormikControls/FormikErrorTracker";
import { eventNames } from "utils/EventsTracking";
import LocationSection from "./LocationSection";
import validationSchema from "./validationSchema";
import t from "../../translate";

const SearchPharmaciesScreen = ({ initialSearchParams, stateOptions, loading, onSkip, onSearch }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Formik initialValues={initialSearchParams} onSubmit={onSearch} validationSchema={validationSchema}>
      <>
        <FormikErrorTracker eventName={eventNames.PHARMACY_SEARCH_FORM_VALIDATION_ERRORS_ON_SUBMISSION} />
        <Page>
          <Page.Layout maxWidth="none" />
          <Page.Form formik />
          <Page.Title>{t("lets_find_it")}</Page.Title>
          <Page.Subtitle>{t("we_will_send_any_prescriptions")}</Page.Subtitle>

          <Page.Content>
            <OneColumnGrid>
              <Box mb={6}>
                <FormikTextField
                  fullWidth
                  helperText={t("spell_note")}
                  label={t("pharmacy_name")}
                  name="name"
                  required
                />
              </Box>
              <LocationSection stateOptions={stateOptions} />
            </OneColumnGrid>
          </Page.Content>

          <Page.ButtonPrimary>{t("search")}</Page.ButtonPrimary>
          {smUp ? (
            <Page.ButtonSecondary onClick={onSkip}>{t("skip")}</Page.ButtonSecondary>
          ) : (
            <Page.ButtonTertiary onClick={onSkip}>{t("skip")}</Page.ButtonTertiary>
          )}
        </Page>
        <FullscreenSpinner visible={loading} />
      </>
    </Formik>
  );
};

export default SearchPharmaciesScreen;
