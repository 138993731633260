import Typography from "@mui/material/Typography";
import Page from "components/Page";
import formatCurrency from "utils/formatCurrency";
import useTranslation from "hooks/useTranslation";
import CheckMarkIcon from "images/base/CheckMarkIcon";

const PaymentSuccessful = ({ onContinue, paidAmount }) => {
  const t = useTranslation("universal.universal_payment.successful");

  return (
    <Page>
      <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />
      <Page.Title>{t("title")}</Page.Title>
      <Page.Icon>
        <CheckMarkIcon height="8em" width="8em" />
      </Page.Icon>
      <Page.Content>
        <Typography textAlign="center" variant="body1">
          {t("description", { amount: formatCurrency(paidAmount) })}
        </Typography>
      </Page.Content>
      <Page.ButtonPrimary onClick={onContinue} />
    </Page>
  );
};

export default PaymentSuccessful;
