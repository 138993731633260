import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import toggleInArray from "utils/toggleInArray";
import CheckboxGroupBase from "components/CheckboxGroup";

const NONE_VALUE = "none";
const ALL_UNCHECKED_VALUE = null;
const NONE_APPLY_CHECKED_VALUE = [];

const CheckboxGroup = ({ error, onChange, question, readOnly, value, label }) => {
  const t = useTranslation("universal.screeners.checkbox_group");
  const answers = value || [];
  const options = question.options.map((option) => ({
    name: option.value,
    ...option,
    checked: answers.includes(option.value),
  }));
  const [noneSelected, setNoneSelected] = useState(readOnly ? !options.some((option) => option.checked) : false);
  const answerNone = `${question.name}_${NONE_VALUE}`;
  const handleChange = (e) => {
    if (readOnly) return;

    if (e.target.name === answerNone) {
      onChange(question.name, { value: e.target.checked ? NONE_APPLY_CHECKED_VALUE : ALL_UNCHECKED_VALUE });
      setNoneSelected(e.target.checked);
    } else {
      const updatedAnswers = toggleInArray(answers, e.target.value);
      onChange(question.name, { value: updatedAnswers.length ? updatedAnswers : ALL_UNCHECKED_VALUE });

      setNoneSelected(false);
    }
  };

  options.push({
    checked: noneSelected,
    divider: true,
    label: t("none_apply"),
    name: answerNone,
    value: answerNone,
  });

  return (
    <CheckboxGroupBase
      disabled={readOnly}
      error={error}
      label={label}
      leftAlign
      onChange={handleChange}
      options={options}
      value={value}
    />
  );
};

export default CheckboxGroup;
