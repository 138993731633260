import { Grid, Typography } from "@mui/material";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikZipField from "components/FormikControls/FormikZipField";
import t from "../translate";

const LocationFields = ({ toggle, patientsFullAddress, stateOptions }) => {
  if (toggle) {
    return (
      <Grid item xs={12}>
        <Typography variant="body1">{patientsFullAddress}</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <FormikTextField fullWidth label={t("guarantoraddress1")} name="address1" required />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField fullWidth label={t("guarantoraddress2")} name="address2" />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField fullWidth label={t("guarantorcity")} name="city" required />
      </Grid>
      <Grid item xs={6}>
        <FormikSelect label={t("guarantorstate")} name="state" options={stateOptions} required />
      </Grid>
      <Grid item xs={6}>
        <FormikZipField fullWidth label={t("guarantorzip")} name="zip" required />
      </Grid>
    </>
  );
};

export default LocationFields;
