import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import Screen from "components/screeners/Screen";
import requestToServer from "utils/requestToServer";
import useTranslation from "hooks/useTranslation";
import useScreenerState from "./useScreenerState";
import { sectionsPropType, questionsPropType } from "../../components/screeners/propTypes";

const SimpleScreener = ({
  action,
  method,
  data: {
    form,
    informationModal,
    inputTypes,
    notes,
    oneColumnLayout,
    questions,
    readOnly,
    screenerType,
    sections,
    skipAllowed,
    warning,
  },
}) => {
  const [informationModalOpen, setInformationModalOpen] = useState(!readOnly && !!informationModal);
  const handleInformationModalButtonClick = () => setInformationModalOpen(false);
  const { values, errors, handleChange, handleSubmit, isSubmitting } = useScreenerState({
    form,
    onSubmit: (responses) => {
      requestToServer({ path: action, method, body: { [screenerType]: responses } });
    },
    questions,
    readOnly,
  });

  const t = useTranslation(`universal.${screenerType}`);
  const handleSkip = () => {
    requestToServer({ path: action, method, body: { skip_screener: true } });
  };

  return (
    <>
      <Screen
        description={t("description")}
        errors={errors}
        inputTypes={inputTypes}
        isSubmitting={isSubmitting}
        notes={notes}
        onChange={handleChange}
        onSkip={skipAllowed ? handleSkip : null}
        onSubmit={handleSubmit}
        oneColumnLayout={oneColumnLayout}
        questions={questions}
        readOnly={readOnly}
        sections={sections}
        title={t("title")}
        values={values}
        warning={warning}
      />
      <Modal
        disableBackdropClick
        doneButtonText={informationModal?.buttonText}
        message={informationModal?.content}
        onDoneButtonClick={handleInformationModalButtonClick}
        open={informationModalOpen}
        shrink
        title={informationModal?.title}
      />
    </>
  );
};

export default SimpleScreener;

SimpleScreener.propTypes = {
  action: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  data: PropTypes.shape({
    form: PropTypes.shape({
      initialValues: PropTypes.object.isRequired,
    }).isRequired,
    informationModal: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    inputTypes: PropTypes.shape({
      desktop: PropTypes.oneOf(["buttonGroup", "checkboxGroup", "number", "radio", "toggleQuestion"]).isRequired,
      mobile: PropTypes.oneOf(["radio", "select"]),
    }),
    notes: PropTypes.arrayOf(PropTypes.string),
    oneColumnLayout: PropTypes.bool.isRequired,
    questions: questionsPropType.isRequired,
    readOnly: PropTypes.bool.isRequired,
    screenerType: PropTypes.string.isRequired,
    sections: sectionsPropType,
    skipAllowed: PropTypes.bool.isRequired,
    warning: PropTypes.string.isRequired,
  }).isRequired,
};
