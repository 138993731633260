import * as yup from "yup";
import citySchema from "utils/yup/citySchema";
import nameSchema from "utils/yup/nameSchema";
import phoneSchema from "utils/yup/phoneSchema";
import zipSchema from "utils/yup/zipSchema";

const getValidationSchema = (relationshipSelfValue) => {
  const buildRequiredForDiffAsSelf = {
    is: (value) => value !== relationshipSelfValue,
    then: (schema) => schema.required(),
  };
  const buildRequiredForDiffAsPatient = { is: false, then: (schema) => schema.required() };

  return yup.object().shape({
    relationshipToPatient: yup.string().required(),
    addressSameAsPatient: yup.boolean(),

    firstName: nameSchema().when("relationshipToPatient", buildRequiredForDiffAsSelf),
    lastName: nameSchema().when("relationshipToPatient", buildRequiredForDiffAsSelf),
    phone: phoneSchema().when("relationshipToPatient", buildRequiredForDiffAsSelf),
    day: yup.string().when("relationshipToPatient", buildRequiredForDiffAsSelf),
    month: yup.string().when("relationshipToPatient", buildRequiredForDiffAsSelf),
    year: yup.string().when("relationshipToPatient", buildRequiredForDiffAsSelf),

    address1: yup.string().max(100).when("addressSameAsPatient", buildRequiredForDiffAsPatient),
    address2: yup.string().max(100),
    city: citySchema().when("addressSameAsPatient", buildRequiredForDiffAsPatient),
    state: yup.string().max(10).when("addressSameAsPatient", buildRequiredForDiffAsPatient),
    zip: zipSchema().when("addressSameAsPatient", buildRequiredForDiffAsPatient),
  });
};

export default getValidationSchema;
