import * as Yup from "yup";
import phoneSchema from "utils/yup/phoneSchema";
import inferContactMethod, { CONTACT_METHODS } from "./inferContactMethod";
import t from "./translate";

const EMAIL_MAX_LENGTH = 50;

const getValidationSchema = ({
  consentsToTextProvided,
  email: initialEmail,
  preferredContactMethodOptions,
  readOnlyEmail,
  emailConfirmationEnabled,
  hideEmail,
  readOnlyPhoneFields,
}) => {
  let emailSchema = Yup.string().ensure().nullable();
  let emailConfirmationSchema = Yup.string().nullable();
  let homePhoneSchema = phoneSchema();

  if (!readOnlyEmail && !hideEmail) {
    emailSchema = emailSchema.when("emailProvided", (emailProvided, schema) => {
      if (emailProvided) {
        return schema.email().max(EMAIL_MAX_LENGTH).required();
      }

      return schema.transform(() => "");
    });

    if (emailConfirmationEnabled && !hideEmail && !readOnlyEmail) {
      emailConfirmationSchema = emailConfirmationSchema.when(
        ["email", "emailProvided"],
        (email, emailProvided, schema) => {
          const emailChanged = email !== initialEmail;
          if (emailProvided && emailChanged) {
            return schema.required().oneOf([Yup.ref("email")], t("form.error_email_confirmation"));
          }
          return schema;
        },
      );
    }
  }

  if (!readOnlyPhoneFields) {
    homePhoneSchema = homePhoneSchema.when("mobilePhone", (mobilePhone, schema) =>
      !mobilePhone ? schema.required() : schema,
    );
  }

  return Yup.object({
    consentsToText: Yup.boolean(),
    email: emailSchema,
    emailConfirmation: emailConfirmationSchema,
    emailProvided: Yup.boolean(),
    homePhone: homePhoneSchema,
    workPhone: phoneSchema(),
    mobilePhone: phoneSchema().when("consentsToText", {
      is: (value) => consentsToTextProvided && value,
      then: (schema) => schema.required(),
    }),
    preferredContactMethod: getPreferredContactMethodSchema(preferredContactMethodOptions),
  });
};

export default getValidationSchema;

function getPreferredContactMethodSchema(preferredContactMethodOptions) {
  const preferredContactMethodSchema = Yup.string().ensure();

  if (!preferredContactMethodOptions?.length) {
    return preferredContactMethodSchema;
  }

  return preferredContactMethodSchema.required().test({
    name: "preferredContactMethodMustBeProvided",
    exclusive: false,
    message: t("preferred_contact_method_not_provided"),
    test(preferredContactMethod) {
      if (!preferredContactMethod) return true;

      const phones = {
        [CONTACT_METHODS.mobilePhone]: this.parent.mobilePhone,
        [CONTACT_METHODS.homePhone]: this.parent.homePhone,
        [CONTACT_METHODS.workPhone]: this.parent.workPhone,
      };

      const contactMethod = inferContactMethod(preferredContactMethod);

      return !contactMethod || !!phones[contactMethod];
    },
  });
}
