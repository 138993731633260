import { useState } from "react";
import requestToServer from "utils/requestToServer";
import initTranslationsHelper from "utils/initTranslationsHelper";
import formatCurrency from "utils/formatCurrency";
import ReceiptEmailScreen from "../ReceiptEmailScreen";

const t = initTranslationsHelper("universal.payments.send_receipt_form");

const PaymentReceiptsEmail = ({ action, method, data: { email, paidAmount } }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = (submittedEmail) => {
    setSubmitting(true);
    requestToServer({ path: action, method, body: { payment_receipts_email: submittedEmail } });
  };
  const handleSkip = () => {
    setSubmitting(true);
    requestToServer({ path: action, method, body: { skip: true } });
  };

  return (
    <ReceiptEmailScreen
      cardTitle={t("complete_form_title")}
      emailFieldInitialValue={email}
      emailFieldLabel={t("complete_email")}
      isSubmitting={isSubmitting}
      onSkip={handleSkip}
      onSubmit={handleSubmit}
      primaryButtonText={t("complete_button")}
      secondaryButtonText={t("complete_skip_button")}
      subtitle={t("description", { amount: formatCurrency(paidAmount) })}
      title={t("title")}
    />
  );
};

export default PaymentReceiptsEmail;
