import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "components/Page";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { translateRelationshipOptions } from "utils/translateOptions";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikPhoneField from "components/FormikControls/FormikPhoneField";
import getValidationSchema from "./getValidationSchema";

const translate = initTranslationsHelper("universal.personal_information.emergency_contact");

const View = ({
  commaSeparatedEmergencyContactEnabled,
  emergencyContactRequired,
  firstName,
  homePhone,
  isSubmitting,
  lastName,
  mobilePhone,
  primaryPhone,
  primaryPhoneEnabled,
  name,
  onSubmit,
  relationship,
  relationshipEnabled,
  relationshipOptions,
}) => {
  const translatedRelationshipOptions = translateRelationshipOptions(relationshipOptions);

  return (
    <Formik
      initialValues={{
        firstName,
        lastName,
        name,
        mobilePhone,
        primaryPhone,
        homePhone,
        relationship,
      }}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(
        commaSeparatedEmergencyContactEnabled,
        emergencyContactRequired,
        primaryPhoneEnabled,
        relationshipEnabled,
      )}
    >
      <Page>
        <Page.Form formik />
        <Page.Title>{translate("title")}</Page.Title>
        <Page.Content>
          <OneColumnGrid>
            <SpacedGrid justify="center">
              {commaSeparatedEmergencyContactEnabled ? (
                <>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      label={translate("first_name")}
                      name="firstName"
                      required={emergencyContactRequired}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      label={translate("last_name")}
                      name="lastName"
                      required={emergencyContactRequired}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <FormikTextField
                    fullWidth
                    label={translate("name")}
                    name="name"
                    required={emergencyContactRequired}
                  />
                </Grid>
              )}
              {primaryPhoneEnabled && (
                <Grid item xs={12}>
                  <FormikPhoneField
                    fullWidth
                    label={translate("primary_phone")}
                    name="primaryPhone"
                    required={emergencyContactRequired}
                  />
                </Grid>
              )}
              {!primaryPhoneEnabled && (
                <>
                  <Grid item xs={6}>
                    <FormikPhoneField fullWidth label={translate("mobile_phone")} name="mobilePhone" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikPhoneField fullWidth label={translate("home_phone")} name="homePhone" />
                  </Grid>
                </>
              )}
              {emergencyContactRequired && !primaryPhoneEnabled && (
                <Grid item xs={12}>
                  <Typography align="center" component="p" variant="caption">
                    {translate("hint")}
                  </Typography>
                </Grid>
              )}
              {relationshipEnabled && (
                <Grid item xs={12}>
                  <FormikSelect
                    emptyOption=""
                    label={translate("relationship")}
                    name="relationship"
                    options={translatedRelationshipOptions}
                    required={emergencyContactRequired}
                  />
                </Grid>
              )}
            </SpacedGrid>
          </OneColumnGrid>
        </Page.Content>
        <Page.RequiredCopy />
        <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting}>
          {translate("save_button")}
        </Page.ButtonPrimary>
      </Page>
    </Formik>
  );
};

export default View;
