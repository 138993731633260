import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import formatCurrency from "utils/formatCurrency";
import StripedList from "previsit/StripedList";
import Modal from "components/Modal";
import OutstandingBalanceModal from "components/OutstandingBalanceModal";
import t from "components/payments/Summary/translate";
import isNil from "lodash/isNil";
import CollectionsBalanceModal from "./CollectionsBalanceModal";

const DetailsList = ({
  claims,
  collectionsBalance,
  copay,
  outstandingBalance,
  selfPayFee,
  showOutstandingBalanceDetails,
}) => {
  const [activeModal, setActiveModal] = useState(null);

  const closeActiveModal = () => setActiveModal(null);

  return (
    <>
      <StripedList odd>
        {!isNil(copay) && (
          <Row>
            <Amount>{formatCurrency(copay)}</Amount>
            <Description>{t("copay_text")}</Description>
          </Row>
        )}
        {!isNil(selfPayFee) && (
          <Row>
            <Amount>{formatCurrency(selfPayFee)}</Amount>
            <Description>{t("appointment_fee_text")}</Description>
            <HelpButton onClick={() => setActiveModal(MODAL_APPOINTMENT_FEE_EXPLANATION)}>
              {t("what_is_this")}
            </HelpButton>
          </Row>
        )}
        {!isNil(outstandingBalance) && (
          <Row>
            <Amount>{formatCurrency(outstandingBalance)}</Amount>
            <Description>{t("outstanding_balance_text")}</Description>
            {showOutstandingBalanceDetails && (
              <HelpButton onClick={() => setActiveModal(MODAL_OUTSTANDING_BALANCE)}>{t("see_details")}</HelpButton>
            )}
          </Row>
        )}
        {!isNil(collectionsBalance) && (
          <Row>
            <Amount>{formatCurrency(collectionsBalance)}</Amount>
            <Description>{t("collections_balance_text")}</Description>
            <HelpButton onClick={() => setActiveModal(MODAL_COLLECTIONS_BALANCE)}>{t("what_is_this")}</HelpButton>
          </Row>
        )}
      </StripedList>
      <Modal
        message={t("appointment_fee_modal.explanation")}
        onDoneButtonClick={closeActiveModal}
        open={activeModal === MODAL_APPOINTMENT_FEE_EXPLANATION}
        title={t("appointment_fee_modal.title")}
      />
      <OutstandingBalanceModal
        claims={claims}
        cleanClaimsSubtitle={t("outstanding_balance_modal.clean_claims_subtitle")}
        cleanClaimsTitle={t("outstanding_balance_modal.clean_claims_title")}
        onDoneButtonClick={closeActiveModal}
        open={activeModal === MODAL_OUTSTANDING_BALANCE}
        uncleanClaimsSubtitle={t("outstanding_balance_modal.unclean_claims_subtitle")}
        uncleanClaimsTitle={t("outstanding_balance_modal.unclean_claims_title")}
      />
      <CollectionsBalanceModal onDoneButtonClick={closeActiveModal} open={activeModal === MODAL_COLLECTIONS_BALANCE} />
    </>
  );
};

export default DetailsList;

const MODAL_APPOINTMENT_FEE_EXPLANATION = "appointment-fee-explanation";
const MODAL_OUTSTANDING_BALANCE = "outstanding-balance";
const MODAL_COLLECTIONS_BALANCE = "collections-balance";

export const Row = ({ children }) => <Grid container>{children}</Grid>;

export const Amount = ({ children }) => {
  const theme = useTheme();

  return (
    <Grid item pl={2} py={2} xs={3}>
      <Typography align="right" color={theme.palette.custom?.blue}>
        {children}
      </Typography>
    </Grid>
  );
};

export const Description = ({ children }) => (
  <Grid item p={2} xs>
    <Typography>{children}</Typography>
  </Grid>
);

export const HelpButton = ({ children, ...props }) => (
  <Grid item pr={2} py={2} xs="auto" zeroMinWidth>
    <Link component="button" {...props}>
      <Typography noWrap style={{ fontWeight: "bold" }} variant="body2">
        {children}
      </Typography>
    </Link>
  </Grid>
);
