import FormField from "components/FormField";
import { Autocomplete, TextField } from "@mui/material";
import { useField } from "formik";
import t from "./translate";

const isOptionEqualToValue = (option, selectedOption) => option.value === selectedOption.value;

const OccupationSelect = ({ occupationOptions }) => {
  const [{ value }, { error, touched }, { setValue }] = useField("occupation");

  const handleChange = (_, newValue) => setValue(newValue?.value);
  const renderInput = (params) => <TextField error={touched && error} {...params} />;
  const selectedOption = occupationOptions.find((option) => option.value === value) || { label: "", value };

  return (
    <FormField error={touched && error} fullWidth id="autocomplete" label={t("occupation_label")}>
      <Autocomplete
        id="autocomplete"
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleChange}
        options={occupationOptions}
        renderInput={renderInput}
        value={selectedOption}
      />
    </FormField>
  );
};

export default OccupationSelect;
