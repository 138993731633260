import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useField } from "formik";
import CheckboxLine from "components/CheckboxLine";
import FormikTextField from "components/FormikControls/FormikTextField";
import { Link, Typography } from "@mui/material";
import t from "./translate";
import ApproveEmailEditingModal from "./ApproveEmailEditingModal";

const EmailFields = ({ initialEmail, readOnly, editEmailModal, emailConfirmationEnabled, officeContactPhone }) => {
  const [{ value: emailProvided }, , { setValue: setEmailProvided }] = useField("emailProvided");
  const [{ value: email }] = useField("email");
  const [approveEmailEditing, setApproveEmailEditing] = useState(false);
  const [approveEmailEditingModalOpen, setApproveEmailEditingModalOpen] = useState(false);

  const needsEmailEditingApproval = editEmailModal && !readOnly && !approveEmailEditing;
  const emailIsReadonly = readOnly || needsEmailEditingApproval;
  const showReadOnlyHint = readOnly && email && officeContactPhone;
  const emailChanged = initialEmail !== email;
  const showEmailConfirmationField = emailConfirmationEnabled && !emailIsReadonly && emailChanged && emailProvided;

  const handleEmailProvidedChange = (e) => setEmailProvided(!e.target.checked);

  return (
    <>
      <Grid alignItems="flex-end" container spacing={1}>
        <Grid item xs>
          <FormikTextField
            disabled={!emailProvided}
            fullWidth
            helperText={showReadOnlyHint ? t("read_only_email_hint", { phone: officeContactPhone }) : undefined}
            inputProps={{ readOnly: emailIsReadonly }}
            label={t("email")}
            name="email"
            required
          />
        </Grid>

        {!emailIsReadonly && (
          <Grid item>
            <Typography component="div" variant="body2">
              <CheckboxLine
                checked={!emailProvided}
                label={t("email_provided_label")}
                name="emailProvided"
                onChange={handleEmailProvidedChange}
                value="true"
              />
            </Typography>
          </Grid>
        )}

        {needsEmailEditingApproval && (
          <Grid item sx={{ pb: 1.25 }}>
            <Link component="button" onClick={() => setApproveEmailEditingModalOpen(true)} type="button">
              {t("form.edit_button_text")}
            </Link>
          </Grid>
        )}

        {showEmailConfirmationField && (
          <Grid item xs={12}>
            <FormikTextField fullWidth label={t("email_confirmation_label")} name="emailConfirmation" required />
          </Grid>
        )}
      </Grid>
      {editEmailModal && !readOnly && (
        <ApproveEmailEditingModal
          onApprove={() => {
            setApproveEmailEditing(true);
            setApproveEmailEditingModalOpen(false);
          }}
          onCancel={() => setApproveEmailEditingModalOpen(false)}
          open={approveEmailEditingModalOpen}
        />
      )}
    </>
  );
};

export default EmailFields;
