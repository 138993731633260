import { Typography } from "@mui/material";
import Modal from "components/Modal";
import t from "./translate";

const ApproveEmailEditingModal = ({ open, onCancel, onApprove }) => (
  <Modal
    doneButtonText={t("approve_email_editing_modal.approve_button_text")}
    onCancelButtonClick={onCancel}
    onDoneButtonClick={onApprove}
    open={open}
    shrink
    title={t("approve_email_editing_modal.title")}
  >
    <Typography paragraph variant="body1">
      {t("approve_email_editing_modal.paragraph_1")}
    </Typography>
    <Typography variant="body1">{t("approve_email_editing_modal.paragraph_2")}</Typography>
  </Modal>
);

export default ApproveEmailEditingModal;
