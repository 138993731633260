import { useField } from "formik";
import PhoneField from "components/PhoneField";

const FormikPhoneField = ({ name, ...props }) => {
  const [{ value, onBlur, onChange }, { touched, error }] = useField(name);

  return (
    <PhoneField
      error={touched && !!error}
      helperText={(touched && error) || ""}
      inputProps={{ onBlur }}
      name={name}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default FormikPhoneField;
