import Page from "components/Page";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikPhoneField from "components/FormikControls/FormikPhoneField";
import FormikSelect from "components/FormikControls/FormikSelect";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import validationSchema from "./validationSchema";
import t from "./translate";

const View = ({ isSubmitting, nextOfKin, onSubmit, relationshipOptions }) => (
  <Formik
    initialValues={{
      firstName: nextOfKin.firstName,
      lastName: nextOfKin.lastName,
      phone: nextOfKin.phone,
      relationship: nextOfKin.relationship,
    }}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    <Page>
      <Page.Form formik />
      <Page.Title>{t("title")}</Page.Title>

      <Page.Content>
        <OneColumnGrid>
          <SpacedGrid>
            <Grid item xs={6}>
              <FormikTextField fullWidth label={t("first_name")} name="firstName" required />
            </Grid>

            <Grid item xs={6}>
              <FormikTextField fullWidth label={t("last_name")} name="lastName" required />
            </Grid>

            <Grid item xs={12}>
              <FormikPhoneField fullWidth label={t("phone_number")} name="phone" required />
            </Grid>

            <Grid item xs={12}>
              <FormikSelect label={t("relationship")} name="relationship" options={relationshipOptions} required />
            </Grid>
          </SpacedGrid>
        </OneColumnGrid>
      </Page.Content>

      <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} />

      <Page.RequiredCopy />
    </Page>
  </Formik>
);

export default View;

View.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  nextOfKin: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
  }).isRequired,
  relationshipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
