import RadioButtonGroup from "components/RadioButtonGroup";
import { useField } from "formik";

const FormikRadioButtonGroup = ({ name, ...props }) => {
  const [{ value, onChange }, { touched, error }] = useField(name);

  return (
    <RadioButtonGroup error={touched ? error : undefined} name={name} onChange={onChange} value={value} {...props} />
  );
};

export default FormikRadioButtonGroup;
