import { useField } from "formik";
import MaskedTextField from "components/MaskedTextField";

const FormikSsnField = ({ name, ...props }) => {
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);

  return (
    <MaskedTextField
      error={touched && error}
      inputMask="###-##-####"
      inputProps={{ onBlur }}
      name={name}
      onChange={onChange}
      supportedSymbols="0-9,*"
      value={value}
      {...props}
    />
  );
};

export default FormikSsnField;
