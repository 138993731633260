import PropTypes from "prop-types";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Page from "components/Page";
import { Formik, useField } from "formik";
import FormikRadioButtonGroup from "components/FormikControls/FormikRadioButtonGroup";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikZipField from "components/FormikControls/FormikZipField";
import FullscreenSpinner from "components/FullscreenSpinner";
import OneColumnGrid from "components/OneColumnGrid";
import {
  CITY_AND_STATE_VALUE,
  ZIP_VALUE,
  GROUP_OR_OFFICE_NAME_VALUE,
  PROVIDER_NAME_VALUE,
} from "components/PCPAndPharmacy/constants";
import LocationFieldsResetter from "components/PCPAndPharmacy/LocationFieldsResetter";
import SpacedGrid from "components/SpacedGrid";
import initTranslationsHelper from "utils/initTranslationsHelper";
import validationSchema from "./validationSchema";
import NameFields from "./NameFields";

const t = initTranslationsHelper("universal.primary_care_provider.form");

const SearchFormScreen = ({ initialValues, loading, onSearch, onSkip, stateOptions }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const locationSearchByOptions = [
    { label: t("zip_code"), value: ZIP_VALUE },
    { label: t("city_and_state"), value: CITY_AND_STATE_VALUE },
  ];

  const nameSearchByOptions = [
    { label: t("provider_name"), value: PROVIDER_NAME_VALUE },
    { label: t("group_or_office"), value: GROUP_OR_OFFICE_NAME_VALUE },
  ];

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSearch} validationSchema={validationSchema}>
        <Page>
          <Page.Form formik />
          <Page.Title>{t("title")}</Page.Title>
          <Page.Content>
            <OneColumnGrid>
              <SpacedGrid>
                <Grid item xs={12}>
                  <Box my={2}>
                    <Typography variant="h6">{t("provider_name")}</Typography>
                    <Box mt={2}>
                      <FormikRadioButtonGroup
                        inline={smUp}
                        label={t("search_by")}
                        name="nameSearchBy"
                        options={nameSearchByOptions}
                        required
                      />
                    </Box>
                  </Box>
                  <NameFields />
                </Grid>
                <Grid item xs={12}>
                  <Box mb={2} mt={3}>
                    <Typography variant="h6">{t("provider_location")}</Typography>
                  </Box>
                  <FormikRadioButtonGroup
                    inline={smUp}
                    label={t("search_by")}
                    name="locationSearchBy"
                    options={locationSearchByOptions}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationFields stateOptions={stateOptions} />
                  <LocationFieldsResetter />
                </Grid>
              </SpacedGrid>
            </OneColumnGrid>
          </Page.Content>
          <Page.ButtonPrimary>{t("search")}</Page.ButtonPrimary>
          {smUp ? (
            <Page.ButtonSecondary onClick={onSkip}>{t("skip")}</Page.ButtonSecondary>
          ) : (
            <Page.ButtonTertiary onClick={onSkip}>{t("skip")}</Page.ButtonTertiary>
          )}
        </Page>
      </Formik>
      <FullscreenSpinner visible={loading} />
    </>
  );
};

const LocationFields = ({ stateOptions }) => {
  const [{ value: locationSearchBy }] = useField("locationSearchBy");
  if (!locationSearchBy) return null;

  if (locationSearchBy === ZIP_VALUE) {
    return (
      <SpacedGrid>
        <Grid item xs={12}>
          <FormikZipField fullWidth label={t("zip")} name="zip" required />
        </Grid>
      </SpacedGrid>
    );
  }

  return (
    <SpacedGrid>
      <Grid item xs={6}>
        <FormikTextField fullWidth id="city" label={t("city")} name="city" required />
      </Grid>
      <Grid item xs={6}>
        <FormikSelect id="state" label={t("state")} name="state" options={stateOptions} required />
      </Grid>
    </SpacedGrid>
  );
};

export default SearchFormScreen;

SearchFormScreen.propTypes = {
  initialValues: PropTypes.shape({
    city: PropTypes.string,
    firstNameOrGroupName: PropTypes.string,
    lastName: PropTypes.string,
    locationSearchBy: PropTypes.string,
    nameSearchBy: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};
