import { useField } from "formik";
import FormField from "components/FormField";
import CheckboxLine from "components/CheckboxLine";

const FormikCheckboxLine = ({ name, ...props }) => {
  const [{ value: checked, onChange }, { touched, error }] = useField(name);

  return (
    <FormField error={touched && error} focused={false} fullWidth>
      <CheckboxLine checked={checked} name={name} onChange={onChange} {...props} />
    </FormField>
  );
};

export default FormikCheckboxLine;
