import { useState } from "react";
import requestToServer from "utils/requestToServer";
import View from "./View";

const Universal = ({
  action,
  method,
  data: { showSSN, showPreferredName, emergencyContact, generalInfo, demographics, genderAndSexuality, nextOfKin },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const handleConfirm = () => {
    setSubmitting(true);
    requestToServer({ body: { quick_confirm: true }, path: action, method });
  };

  const {
    contactName,
    contactPrimaryPhone,
    contactMobilePhone,
    contactHomePhone,
    contactRelationship,
    editPath: emergencyContactEditPath,
  } = emergencyContact || {};
  const { preferredName, ofEmancipationAge, maritalStatus, ssn, editPath: generalInfoEditPath } = generalInfo || {};
  const {
    occupation,
    freeTextOccupation,
    race,
    language,
    ethnicity,
    editPath: demographicsEditPath,
  } = demographics || {};
  const {
    assignedSex,
    genderIdentity,
    preferredPronouns,
    sexualOrientation,
    altFirstName,
    editPath: genderAndSexualityEditPath,
  } = genderAndSexuality || {};
  const { fullName, phone, relationship, editPath: nextOfKinEditPath } = nextOfKin || {};

  return (
    <View
      demographics={{
        demographicsEnabled: !!demographics,
        occupation,
        freeTextOccupation,
        race,
        language,
        ethnicity,
        demographicsEditPath,
      }}
      emergencyContact={{
        emergencyContactEnabled: !!emergencyContact,
        contactName,
        contactPrimaryPhone,
        contactMobilePhone,
        contactHomePhone,
        contactRelationship,
        emergencyContactEditPath,
      }}
      genderAndSexuality={{
        genderAndSexualityEnabled: !!genderAndSexuality,
        assignedSex,
        genderIdentity,
        preferredPronouns,
        sexualOrientation,
        altFirstName,
        genderAndSexualityEditPath,
      }}
      generalInfo={{
        generalInfoEnabled: !!generalInfo,
        showPreferredName,
        preferredName,
        ofEmancipationAge,
        maritalStatus,
        showSSN,
        ssn,
        generalInfoEditPath,
      }}
      isSubmitting={isSubmitting}
      nextOfKin={{
        nextOfKinEnabled: !!nextOfKin,
        fullName,
        phone,
        relationship,
        nextOfKinEditPath,
      }}
      onConfirm={handleConfirm}
    />
  );
};

export default Universal;
