import { useState, useEffect } from "react";
import { trackEvent, eventNames } from "utils/EventsTracking";
import Page from "components/Page";
import useTranslation from "hooks/useTranslation";
import requestToServer from "utils/requestToServer";
import EpionAuthorizationModal from "./Modal";
import EpionAuthorizationSignatureForm from "./SignatureForm";

const EpionAuthorization = ({ action, method, data: { alreadySigned, epionAuthModalProperties } }) => {
  const [showSignatureView, setShowSignatureView] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const t = useTranslation("universal.epion_authorization");

  useEffect(() => {
    trackEvent(eventNames.EPION_AUTHORIZATION_PRESENTED);
  }, []);

  const handleAgree = () => {
    if (alreadySigned) {
      submitForm();
    } else {
      setShowSignatureView(true);
    }
  };

  const handleSkip = () => {
    trackEvent(eventNames.EPION_AUTHORIZATION_SKIPPED);
    submitForm({ skip_agreement: true });
  };

  const submitForm = (data = {}) => {
    if (!isSubmitting) {
      setSubmitting(true);
      requestToServer({ body: data, path: action, method });
    }
  };

  if (showSignatureView) {
    return <EpionAuthorizationSignatureForm path={action} />;
  }

  return (
    <Page>
      <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />
      {alreadySigned && <Page.Alert severity="info">{t("alert_text")}</Page.Alert>}
      <Page.Title>{t("epion_authorization")}</Page.Title>
      <Page.Subtitle>{t("only_patient_can_authorize")}</Page.Subtitle>
      <Page.Content>
        <EpionAuthorizationModal epionAuthModalProperties={epionAuthModalProperties} type="list" />
      </Page.Content>
      <Page.ButtonPrimary disabled={isSubmitting} onClick={handleAgree}>
        {alreadySigned ? t("continue") : t("agree_and_sign")}
      </Page.ButtonPrimary>
      {!alreadySigned && (
        <Page.ButtonTertiary disabled={isSubmitting} loading={isSubmitting} onClick={handleSkip}>
          {t("skip_epion_authorization")}
        </Page.ButtonTertiary>
      )}
    </Page>
  );
};

export default EpionAuthorization;
