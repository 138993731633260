import cond from "lodash/cond";
import constant from "lodash/constant";

export const CONTACT_METHODS = Object.freeze({
  mobilePhone: "MOBILE_PHONE",
  homePhone: "HOME_PHONE",
  workPhone: "WORK_PHONE",
});

const createMatcher = (regex) => (string) => regex.test(string);

const isMobilePhone = createMatcher(/mobile/i);
const isHomePhone = createMatcher(/home/i);
const isWorkPhone = createMatcher(/work/i);

const inferContactMethod = cond([
  [isMobilePhone, constant(CONTACT_METHODS.mobilePhone)],
  [isHomePhone, constant(CONTACT_METHODS.homePhone)],
  [isWorkPhone, constant(CONTACT_METHODS.workPhone)],
]);

export default inferContactMethod;
