import FormikSelectMultiple from "components/FormikControls/FormikSelectMultiple";
import FormikCheckboxLine from "components/FormikControls/FormikCheckboxLine";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import t from "./translate";

const RaceSelect = ({ raceOptions, ratherNotSayOptionsPresent }) => {
  const {
    values: { races, raceRatherNotSay },
    errors: { raceRatherNotSay: raceRatherNotSayError },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (races.length > 0) setFieldValue("raceRatherNotSay", false);
  }, [setFieldValue, races]);

  useEffect(() => {
    if (raceRatherNotSay) setFieldValue("races", []);
  }, [setFieldValue, raceRatherNotSay]);

  return (
    <>
      <FormikSelectMultiple
        controlledError={!!raceRatherNotSayError}
        fullWidth
        label={t("race_label")}
        leftAlign
        name="races"
        options={raceOptions}
        placeholder={t("search")}
        required
      />
      {ratherNotSayOptionsPresent && (
        <FormikCheckboxLine
          checked={raceRatherNotSay}
          label={t("rather_not_say")}
          leftAlign
          name="raceRatherNotSay"
          required
          sx={{ py: 1 }}
        />
      )}
    </>
  );
};

export default RaceSelect;
