import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import initTranslationsHelper from "utils/initTranslationsHelper";
import QuickConfirm from "components/QuickConfirm";
import LocationForm from "./Form";

const translate = initTranslationsHelper("universal.personal_information.location");

const View = ({
  address1,
  address2,
  city,
  isSubmitting,
  onConfirm,
  onSubmit,
  showForm: showFormInitialState,
  state,
  stateOptions,
  zip,
}) => {
  const theme = useTheme();
  const [showForm, setShowForm] = useState(showFormInitialState);

  const handleUpdateClick = () => {
    setShowForm(true);
  };

  if (showForm) {
    return (
      <LocationForm
        initialValues={{
          address1,
          address2,
          city,
          state,
          zip,
        }}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        stateOptions={stateOptions}
      />
    );
  }

  return (
    <QuickConfirm
      icon={theme.icons.location}
      itemList={
        <>
          <Typography align="center" color="textPrimary" variant="body1">
            {address1}
          </Typography>
          <Typography align="center" color="textPrimary" variant="body1">
            {address2}
          </Typography>
          <Typography align="center" color="textPrimary" variant="body1">
            {`${city}, ${state}, ${zip}`}
          </Typography>
        </>
      }
      message={translate("address_complete_and_up_to_date")}
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={handleUpdateClick}
      secondaryButtonText={translate("update_button")}
    />
  );
};

export default View;
export { default as validationSchema } from "./validationSchema";
