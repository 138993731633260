import { useEffect } from "react";
import { useFormikContext } from "formik";
import FormikSelect from "components/FormikControls/FormikSelect";
import t from "./translate";
import FormikCheckboxLine from "components/FormikControls/FormikCheckboxLine";

const LanguageSelect = ({ languageOptions, ratherNotSayOptionsPresent }) => {
  const {
    values: { language, languageRatherNotSay },
    errors: { languageRatherNotSay: languageRatherNotSayError },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (!!language) setFieldValue("languageRatherNotSay", false);
  }, [setFieldValue, language]);

  useEffect(() => {
    if (languageRatherNotSay) setFieldValue("language", "");
  }, [setFieldValue, languageRatherNotSay]);

  return (
    <>
      <FormikSelect
        controlledError={!!languageRatherNotSayError}
        label={t("language_label")}
        name="language"
        options={languageOptions}
        placeholder={t("search")}
        required
      />
      {ratherNotSayOptionsPresent && (
        <FormikCheckboxLine label={t("rather_not_say")} leftAlign name="languageRatherNotSay" required sx={{ py: 1 }} />
      )}
    </>
  );
};

export default LanguageSelect;
