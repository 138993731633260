import * as yup from "yup";
import nameSchema from "utils/yup/nameSchema";
import phoneSchema from "utils/yup/phoneSchema";
import initTranslationsHelper from "utils/initTranslationsHelper";

const translate = initTranslationsHelper("universal.personal_information.emergency_contact");

const getValidationSchema = (
  commaSeparatedEmergencyContactEnabled,
  emergencyContactRequired,
  primaryPhoneEnabled,
  relationshipEnabled,
) => {
  if (!emergencyContactRequired) {
    return yup.object({
      ...(commaSeparatedEmergencyContactEnabled && {
        firstName: nameSchema(),
        lastName: nameSchema(),
      }),
      ...(!commaSeparatedEmergencyContactEnabled && { name: yup.string() }),
      ...(primaryPhoneEnabled && { primaryPhone: getPhoneSchema({ type: "primary" }) }),
      ...(!primaryPhoneEnabled && {
        mobilePhone: getPhoneSchema({ type: "mobile" }),
        homePhone: getPhoneSchema({ type: "home" }),
      }),
      ...(relationshipEnabled && { relationship: yup.string() }),
    });
  }

  return yup.object().shape(
    {
      ...(commaSeparatedEmergencyContactEnabled && {
        firstName: nameSchema().required(),
        lastName: nameSchema().required(),
      }),
      ...(!commaSeparatedEmergencyContactEnabled && {
        name: yup.string().required(),
      }),
      ...(primaryPhoneEnabled && {
        primaryPhone: getPhoneSchema({ required: true, type: "primary" }),
      }),
      ...(!primaryPhoneEnabled && {
        mobilePhone: yup.string().when("homePhone", {
          is: (homePhone) => !homePhone,
          then: getPhoneSchema({ required: true, type: "mobile" }),
          otherwise: getPhoneSchema({ type: "mobile" }),
        }),
        homePhone: yup.string().when("mobilePhone", {
          is: (mobilePhone) => !mobilePhone,
          then: getPhoneSchema({ required: true, type: "home" }),
          otherwise: getPhoneSchema({ type: "home" }),
        }),
      }),
      ...(relationshipEnabled && { relationship: yup.string().required() }),
    },
    [["mobilePhone", "homePhone"]],
  );
};

const getPhoneSchema = ({ required, type } = {}) => {
  if (required) {
    if (type === "primary") {
      return phoneSchema().required();
    }
    return phoneSchema().required(translate("error_at_least_one_phone_number_required"));
  }

  return phoneSchema();
};

export default getValidationSchema;
