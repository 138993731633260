import "utils/importAndInitJQuery";
import "formBuilder/dist/form-render.min";
import initFormBuilderControls from "formBuilder/initFormBuilderControls";

initFormBuilderControls();
const LANG_PATH = "/formbuilder/lang/";

function getRenderedFormFromSchema(schema) {
  const formData = JSON.stringify(schema.controls);
  const $root = $("<div />");
  $root.formRender({
    dataType: "json",
    formData,
    i18n: {
      location: LANG_PATH,
    },
  });
  const renderedHtml = $root.html();
  return renderedHtml;
}

export default getRenderedFormFromSchema;
