import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import View from "./View";

const GenderAndSexuality = ({
  action,
  method,
  data: {
    altFirstName,
    altFirstNameEnabled,
    answersRequired,
    assignedSex,
    assignedSexEnabled,
    assignedSexOptions,
    genderIdentity,
    genderIdentityEnabled,
    genderIdentityOptions,
    genderIdentityOther,
    genderIdentityOtherEnabled,
    genderIdentityOtherOption,
    preferredPronouns,
    preferredPronounsEnabled,
    preferredPronounsOptions,
    sexualOrientation,
    sexualOrientationEnabled,
    sexualOrientationOptions,
    sexualOrientationOther,
    sexualOrientationOtherEnabled,
    sexualOrientationOtherOption,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = (values) => {
    setSubmitting(true);
    requestToServer({
      path: action,
      method,
      body: transformKeysToSnakeCase({
        genderAndSexuality: values,
      }),
    });
  };

  return (
    <View
      altFirstName={altFirstName}
      altFirstNameEnabled={altFirstNameEnabled}
      answersRequired={answersRequired}
      assignedSex={assignedSex}
      assignedSexEnabled={assignedSexEnabled}
      assignedSexOptions={assignedSexOptions}
      genderIdentity={genderIdentity}
      genderIdentityEnabled={genderIdentityEnabled}
      genderIdentityOptions={genderIdentityOptions}
      genderIdentityOther={genderIdentityOther}
      genderIdentityOtherEnabled={genderIdentityOtherEnabled}
      genderIdentityOtherOption={genderIdentityOtherOption}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      preferredPronouns={preferredPronouns}
      preferredPronounsEnabled={preferredPronounsEnabled}
      preferredPronounsOptions={preferredPronounsOptions}
      sexualOrientation={sexualOrientation}
      sexualOrientationEnabled={sexualOrientationEnabled}
      sexualOrientationOptions={sexualOrientationOptions}
      sexualOrientationOther={sexualOrientationOther}
      sexualOrientationOtherEnabled={sexualOrientationOtherEnabled}
      sexualOrientationOtherOption={sexualOrientationOtherOption}
    />
  );
};

export default GenderAndSexuality;
