import { useEffect } from "react";
import { useFormikContext } from "formik";
import { CITY_AND_STATE_VALUE, ZIP_VALUE } from "./constants";

const LocationFieldsResetter = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    switch (values.locationSearchBy) {
      case ZIP_VALUE:
        setFieldValue("city", "");
        setFieldValue("state", "");
        break;
      case CITY_AND_STATE_VALUE:
        setFieldValue("zip", "");
        break;
      default:
        break;
    }
  }, [values.locationSearchBy, setFieldValue]);

  return null;
};

export default LocationFieldsResetter;
