import { useState } from "react";
import { trackEvent, eventNames } from "utils/EventsTracking";
import SignatureForm from "previsit/SignatureForm";
import initTranslationsHelper from "utils/initTranslationsHelper";
import requestToServer from "utils/requestToServer";

const t = initTranslationsHelper("universal.signature_form");

const EpionAuthorizationSignatureForm = ({ path }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSave = (signatureData) => {
    setSubmitting(true);
    trackEvent(eventNames.EPION_AUTHORIZATION_SIGNED);
    requestToServer({
      body: {
        consent_form: signatureData,
      },
      path,
      method: "POST",
    });
  };

  return (
    <SignatureForm
      description={t("description")}
      isSubmitting={isSubmitting}
      onSave={handleSave}
      title={t("add_signature")}
    />
  );
};

export default EpionAuthorizationSignatureForm;
