import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import View from "./View";

const Universal = ({
  action,
  method,
  data: {
    addressSameAsPatient,
    address1,
    address2,
    city,
    dob,
    firstName,
    lastName,
    phone,
    state,
    zip,
    initialDay,
    initialMonth,
    initialYear,
    monthOptions,
    patientsFullAddress,
    relationshipOptions,
    relationshipSelfValue,
    relationshipToPatient,
    stateOptions,
    yearOptions,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const submit = (data) => {
    setSubmitting(true);
    requestToServer({ path: action, method, body: transformKeysToSnakeCase(data) });
  };

  const handleConfirm = () => submit({ quickConfirm: true });
  const handleSubmit = (values) => submit({ guarantor: values });

  return (
    <View
      address1={address1}
      address2={address2}
      addressSameAsPatient={addressSameAsPatient}
      city={city}
      dob={dob}
      firstName={firstName}
      initialDay={initialDay}
      initialMonth={initialMonth}
      initialYear={initialYear}
      isSubmitting={isSubmitting}
      lastName={lastName}
      monthOptions={monthOptions}
      onConfirm={handleConfirm}
      onSubmit={handleSubmit}
      patientsFullAddress={patientsFullAddress}
      phone={phone}
      relationshipOptions={relationshipOptions}
      relationshipSelfValue={relationshipSelfValue}
      relationshipToPatient={relationshipToPatient}
      state={state}
      stateOptions={stateOptions}
      yearOptions={yearOptions}
      zip={zip}
    />
  );
};
export default Universal;
