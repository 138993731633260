import { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import t from "../translate";

const FixedFeeDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Box textAlign="center">
        <Typography variant="caption">
          <Link component="button" onClick={() => setModalOpen(true)} variant="body2">
            {t("what_is_this")}
          </Link>
        </Typography>
      </Box>
      <Modal
        onDoneButtonClick={() => setModalOpen(false)}
        open={modalOpen}
        subtitle={t("modal_explanation_fixed_fee")}
        title={t("what_is_this")}
      />
    </>
  );
};

export default FixedFeeDetails;
