import { useState } from "react";
import PropTypes from "prop-types";
import initTranslationsHelper from "utils/initTranslationsHelper";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import { Formik } from "formik";
import Page from "components/Page";
import Link from "@mui/material/Link";
import { Box, Grid, Typography } from "@mui/material";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import Modal from "components/Modal";
import Bold from "components/Typography/Bold";
import useTranslation from "hooks/useTranslation";
import getValidationSchema from "./getValidationSchema";

const translate = initTranslationsHelper("universal.personal_information.gender_and_sexuality");

const View = ({
  altFirstName,
  altFirstNameEnabled,
  answersRequired,
  assignedSex,
  assignedSexEnabled,
  assignedSexOptions,
  genderIdentity,
  genderIdentityEnabled,
  genderIdentityOptions,
  genderIdentityOther,
  genderIdentityOtherEnabled,
  genderIdentityOtherOption,
  isSubmitting,
  onSubmit,
  preferredPronouns,
  preferredPronounsEnabled,
  preferredPronounsOptions,
  sexualOrientation,
  sexualOrientationEnabled,
  sexualOrientationOptions,
  sexualOrientationOther,
  sexualOrientationOtherEnabled,
  sexualOrientationOtherOption,
}) => {
  const [showModal, setShowModal] = useState(false);
  const additionalGenderRegexp = new RegExp(genderIdentityOtherOption, "i");
  const somethingElseRegexp = new RegExp(sexualOrientationOtherOption, "i");
  const enabledData = {
    altFirstNameEnabled,
    assignedSexEnabled,
    genderIdentityEnabled,
    genderIdentityOtherEnabled,
    preferredPronounsEnabled,
    sexualOrientationEnabled,
    sexualOrientationOtherEnabled,
  };

  return (
    <Formik
      initialValues={{
        assignedSex: assignedSexOptions.find((option) => option.value === assignedSex)?.value || "",
        genderIdentity,
        genderIdentityOther,
        preferredPronouns,
        sexualOrientation,
        sexualOrientationOther,
        altFirstName,
      }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={getValidationSchema(
        answersRequired,
        additionalGenderRegexp,
        somethingElseRegexp,
        useTranslation("shared.validation"),
        enabledData,
      )}
    >
      {({ values }) => (
        <Page>
          <Page.Form formik />

          <Page.Title>{translate("title")}</Page.Title>
          <Page.Subtitle>
            <Link component="button" onClick={() => setShowModal(true)} type="button" variant="body1">
              {translate("hint_link")}
            </Link>
          </Page.Subtitle>

          <Page.Content>
            <OneColumnGrid>
              <SpacedGrid direction="column">
                {assignedSexEnabled && (
                  <Grid item xs>
                    <FormikSelect
                      emptyOption=""
                      label={translate("assigned_sex")}
                      name="assignedSex"
                      options={assignedSexOptions}
                      required={answersRequired}
                    />
                  </Grid>
                )}
                {genderIdentityEnabled && (
                  <Grid item xs>
                    <FormikSelect
                      disableEmptyOption={!!genderIdentity}
                      emptyOption=""
                      label={translate("gender_identity")}
                      name="genderIdentity"
                      options={genderIdentityOptions}
                      required={answersRequired}
                    />
                  </Grid>
                )}
                {genderIdentityOtherEnabled && additionalGenderRegexp.test(values.genderIdentity) && (
                  <Grid item xs>
                    <FormikTextField
                      fullWidth
                      label={translate("gender_identity_other")}
                      name="genderIdentityOther"
                      required
                    />
                  </Grid>
                )}
                {preferredPronounsEnabled && (
                  <Grid item xs>
                    <FormikSelect
                      emptyOption=""
                      label={translate("preferred_pronouns")}
                      name="preferredPronouns"
                      options={preferredPronounsOptions}
                      required={answersRequired}
                    />
                  </Grid>
                )}
                {sexualOrientationEnabled && (
                  <Grid item xs>
                    <FormikSelect
                      disableEmptyOption={!!sexualOrientation}
                      emptyOption=""
                      label={translate("sexual_orientation")}
                      name="sexualOrientation"
                      options={sexualOrientationOptions}
                      required={answersRequired}
                    />
                  </Grid>
                )}
                {sexualOrientationOtherEnabled && somethingElseRegexp.test(values.sexualOrientation) && (
                  <Grid item xs>
                    <FormikTextField
                      fullWidth
                      label={translate("sexual_orientation_other")}
                      name="sexualOrientationOther"
                      required
                    />
                  </Grid>
                )}
                {altFirstNameEnabled && (
                  <Grid item xs>
                    <FormikTextField
                      fullWidth
                      label={translate("alt_first_name")}
                      name="altFirstName"
                      required={answersRequired}
                    />
                  </Grid>
                )}
              </SpacedGrid>
            </OneColumnGrid>
            <Modal
              onDoneButtonClick={() => setShowModal(false)}
              open={showModal}
              title={translate("hint_modal.title")}
              wide
            >
              <Box pb={4}>
                <Typography variant="subtitle1">
                  <Bold>{translate("hint_modal.subtitle_1")}</Bold>
                </Typography>
                <Typography variant="body1">{translate("hint_modal.description_1")}</Typography>
              </Box>
              <Box pb={4}>
                <Typography variant="subtitle1">
                  <Bold>{translate("hint_modal.subtitle_2")}</Bold>
                </Typography>
                <Typography variant="body1">{translate("hint_modal.description_2")}</Typography>
              </Box>
            </Modal>
          </Page.Content>
          {answersRequired && <Page.RequiredCopy />}
          <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting}>
            {translate("save_button")}
          </Page.ButtonPrimary>
        </Page>
      )}
    </Formik>
  );
};

export default View;

const optionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

View.propTypes = {
  altFirstName: PropTypes.string.isRequired,
  altFirstNameEnabled: PropTypes.bool.isRequired,
  answersRequired: PropTypes.bool.isRequired,
  assignedSex: PropTypes.string.isRequired,
  assignedSexEnabled: PropTypes.bool.isRequired,
  assignedSexOptions: optionsPropType.isRequired,
  genderIdentity: PropTypes.string.isRequired,
  genderIdentityEnabled: PropTypes.bool.isRequired,
  genderIdentityOptions: optionsPropType.isRequired,
  genderIdentityOther: PropTypes.string,
  genderIdentityOtherEnabled: PropTypes.bool,
  genderIdentityOtherOption: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  preferredPronouns: PropTypes.string.isRequired,
  preferredPronounsEnabled: PropTypes.bool.isRequired,
  preferredPronounsOptions: optionsPropType.isRequired,
  sexualOrientation: PropTypes.string.isRequired,
  sexualOrientationEnabled: PropTypes.bool.isRequired,
  sexualOrientationOptions: optionsPropType.isRequired,
  sexualOrientationOther: PropTypes.string,
  sexualOrientationOtherEnabled: PropTypes.bool,
  sexualOrientationOtherOption: PropTypes.string.isRequired,
};

View.defaultProps = {
  genderIdentityOther: undefined,
  genderIdentityOtherEnabled: false,
  sexualOrientationOther: undefined,
  sexualOrientationOtherEnabled: false,
};
