import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useTranslation from "hooks/useTranslation";
import InfoBox from "./InfoBox";

const InfoPanel = () => {
  const theme = useTheme();
  const t = useTranslation("universal.epion_authorization");

  return (
    <>
      <Box sx={{ borderRadius: 2, boxShadow: 4, overflow: "hidden" }}>
        <Typography
          align="center"
          sx={{
            backgroundColor: theme.palette.basePrimary,
            color: theme.palette.background.default,
            fontWeight: "bold",
            p: 1,
          }}
          variant="body1"
        >
          {t("how_does_it_work")}
        </Typography>
        <Grid container justifyContent="center" spacing={2.5} sx={{ p: 2, pb: 3 }}>
          <InfoBox description={t("info_description_1")} icon={theme.icons.handshake} title={t("info_title_1")} />
          <InfoBox description={t("info_description_2")} icon={theme.icons.present} title={t("info_title_2")} />
          <InfoBox description={t("info_description_3")} icon={theme.icons.outbox} title={t("info_title_3")} />
        </Grid>
      </Box>
      <Grid container mt={1} spacing={2}>
        <InfoBox
          description={t("info_description_4")}
          gridWidth={6}
          icon={theme.icons.lock}
          separateIconLine
          title={t("info_title_4")}
        />
        <InfoBox
          description={t("info_description_5")}
          gridWidth={6}
          icon={theme.icons.luggage}
          separateIconLine
          title={t("info_title_5")}
        />
      </Grid>
    </>
  );
};

export default InfoPanel;
