import * as Yup from "yup";
import zipSchema from "utils/yup/zipSchema";
import citySchema from "utils/yup/citySchema";
import { CITY_AND_STATE_VALUE, ZIP_VALUE } from "components/PCPAndPharmacy/constants";

const validationSchema = Yup.object({
  name: Yup.string().min(3).max(100).required(),
  zip: zipSchema().when("locationSearchBy", (locationSearchBy, schema) =>
    locationSearchBy === ZIP_VALUE ? schema.required() : schema,
  ),
  locationSearchBy: Yup.string().required(),
  city: citySchema().when("locationSearchBy", (locationSearchBy, schema) =>
    locationSearchBy === CITY_AND_STATE_VALUE ? schema.required() : schema,
  ),
  state: Yup.string()
    .max(10)
    .when("locationSearchBy", (locationSearchBy, schema) =>
      locationSearchBy === CITY_AND_STATE_VALUE ? schema.required() : schema,
    ),
});

export default validationSchema;
