import { Box, Typography, Grid } from "@mui/material";

const InfoBox = ({ description, gridWidth, icon, separateIconLine, title }) => (
  <Grid item xs={gridWidth}>
    <Box
      sx={{
        display: "flex",
        flexDirection: separateIconLine ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: 24, mr: separateIconLine ? 0 : 1 }}>{icon}</Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: 14 }} variant="body1">
        {title}
      </Typography>
    </Box>
    <Typography align="center" component="div" fontWeight="light" variant="caption">
      {description}
    </Typography>
  </Grid>
);

export default InfoBox;
