import * as Yup from "yup";
import initTranslationsHelper from "utils/initTranslationsHelper";

const defaultTranslate = initTranslationsHelper("shared.validation");

const citySchema = (translate) =>
  Yup.string()
    .max(30)
    .matches(/^[^0-9]*$/, (translate || defaultTranslate)("error_alphabetic"));

export default citySchema;
