import * as Yup from "yup";
import phoneSchema from "utils/yup/phoneSchema";
import t from "./translate";

const NAME_MAX_CHARS = 25;
const LETTERS_ONLY_REGEX = /^[^\W^\d]*$/;
const nameSchema = Yup.string()
  .trim()
  .max(NAME_MAX_CHARS)
  .matches(LETTERS_ONLY_REGEX, t("error_invalid_characters_used"))
  .required();

const validationSchema = Yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  phone: phoneSchema().required(),
  relationship: Yup.string().required(),
});

export default validationSchema;
