import RadioButtonGroup from "components/RadioButtonGroup";
import Select from "components/Select";
import initTranslationsHelper from "utils/initTranslationsHelper";

const t = initTranslationsHelper("universal.screeners");

const withEdgeResponsesLabels = (options, edgeResponsesLabels) => {
  const first = options[0];
  const last = options[options.length - 1];
  const middle = options.slice(1, -1);

  return [withEdgeLabel(first, edgeResponsesLabels[0]), ...middle, withEdgeLabel(last, edgeResponsesLabels[1])];
};

const withEdgeLabel = (option, edgeLabel) => ({
  ...option,
  label: `${option.label} - ${edgeLabel}`,
});

const Mobile = ({ onChange, question, readOnly, type, value }) => {
  switch (type) {
    case "radio":
      return (
        <RadioButtonGroup
          name={question.name}
          onChange={(e) => !readOnly && onChange(question.name, { value: e.target.value })}
          options={question.options}
          value={value || ""}
        />
      );
    default:
      return (
        <Select
          emptyOption={t("select")}
          name={question.name}
          onChange={(e) => !readOnly && onChange(question.name, { value: e.target.value })}
          options={withEdgeResponsesLabels(question.options, question.edgeResponsesLabels)}
          value={value || ""}
        />
      );
  }
};

export default Mobile;
