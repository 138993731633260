import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import SignaturePadView from "./SignaturePadView";
import SignOnBehalfForm from "./SignOnBehalfForm";
import { SIGNER_TYPE } from "./SignOnBehalfForm/constants";

const SignatureForm = ({
  caregiverAttributes,
  caregiverSignatureEnabled,
  description,
  initialDrawingInstructions,
  isSubmitting,
  onSave,
  showCaregiverSignatureAddress,
  title,
}) => {
  const [signature, setSignature] = useState({
    drawingInstructions: initialDrawingInstructions,
  });
  const [signerData, setSignerData] = useState({ signerType: SIGNER_TYPE.patient });
  const [showSignOnBehalfForm, setShowSignOnBehalfForm] = useState(caregiverSignatureEnabled);
  const t = useTranslation("universal.signature_form");

  const handleSignatureChange = (drawingInstructions) => {
    setSignature({
      drawingInstructions,
    });
  };

  const handleContinue = () => {
    const signatureData = {
      signature_attributes: {
        drawing_instructions: JSON.stringify(signature.drawingInstructions),
        signer_type: signerData.signerType,
      },
    };

    if (caregiverSignatureEnabled && signerData.signerType === SIGNER_TYPE.caregiver) {
      signatureData.caregiver_attributes = {
        first_name: signerData.firstName,
        last_name: signerData.lastName,
        reason: signerData.reason,
        relationship: signerData.relationship,
        ...(showCaregiverSignatureAddress && {
          address1: signerData.addressLine1,
          address2: signerData.addressLine2,
          city: signerData.city,
          state: signerData.state,
          zip: signerData.zip,
        }),
      };
    }
    onSave(signatureData);
  };

  const handleSignOnBehalfFormContinue = (caregiverValues) => {
    setSignerData(caregiverValues);
    setShowSignOnBehalfForm(false);
  };

  if (showSignOnBehalfForm) {
    return (
      <SignOnBehalfForm
        caregiverAttributes={caregiverAttributes}
        key="signOnBehalfForm"
        onContinue={handleSignOnBehalfFormContinue}
        showAddress={showCaregiverSignatureAddress}
        title={title || t("title")}
      />
    );
  }

  const { drawingInstructions } = signature;

  return (
    <SignaturePadView
      description={description}
      drawingInstructions={drawingInstructions}
      isSubmitting={isSubmitting}
      key="SignaturePadView"
      onContinue={handleContinue}
      onSignatureChange={handleSignatureChange}
      title={title}
    />
  );
};

SignatureForm.defaultProps = {
  caregiverAttributes: {},
  caregiverSignatureEnabled: false,
  initialDrawingInstructions: [],
  onSave: () => {},
};

export default SignatureForm;
