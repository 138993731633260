import initTranslationsHelper from "utils/initTranslationsHelper";
import SearchResults from "components/PCPAndPharmacy/SearchResults";

const translate = initTranslationsHelper("universal.primary_care_provider");

const SearchResultsScreen = ({ loading, onEdit, onNotFound, onSelect, paginationEnabled, results, searchParams }) => (
  <SearchResults
    cantFindButtonText={translate("cant_find_provider")}
    confirmQuestion={translate("confirm_primary_care_provider")}
    loading={loading}
    noResultsHint={translate("no_results_hint")}
    onEdit={onEdit}
    onNotFound={onNotFound}
    onSelect={onSelect}
    paginationEnabled={paginationEnabled}
    results={results}
    searchParams={searchParams}
    title={translate("provider_search_results")}
  />
);

export default SearchResultsScreen;
