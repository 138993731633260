import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import ConsentsToTextCheckbox from "components/ConsentsToTextCheckbox";
import Page from "components/Page";
import FormikPhoneField from "components/FormikControls/FormikPhoneField";
import { translatePreferredContactMethodOptions } from "utils/translateOptions";
import SpacedGrid from "components/SpacedGrid";
import OneColumnGrid from "components/OneColumnGrid";
import PreferredContactMethod from "./PreferredContactMethod";
import t from "./translate";
import EmailFields from "./EmailFields";

const ContactInfoForm = ({
  consentsToText,
  consentsToTextProvided,
  editEmailModal,
  email,
  emailConfirmationEnabled,
  emailProvided,
  hideEmail,
  hideWorkPhone,
  homePhone,
  isSubmitting,
  mobilePhone,
  officeContactPhone,
  onSubmit,
  preferredContactMethod,
  preferredContactMethodOptions,
  readOnlyEmail,
  readOnlyPhoneFields,
  validationSchema,
  workPhone,
}) => {
  const initialEmail = email || "";
  const translatedPreferredContactMethodOptions = translatePreferredContactMethodOptions(preferredContactMethodOptions);

  return (
    <Formik
      initialValues={{
        consentsToText,
        email: initialEmail,
        emailProvided,
        mobilePhone,
        homePhone,
        workPhone,
        emailConfirmation: "",
        preferredContactMethod,
      }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Page>
          <Page.Form formik />

          <Page.Title>{t("form.title")}</Page.Title>

          <Page.Content>
            <OneColumnGrid>
              <SpacedGrid>
                {!hideEmail && (
                  <Grid item xs={12}>
                    <EmailFields
                      editEmailModal={editEmailModal}
                      emailConfirmationEnabled={emailConfirmationEnabled}
                      initialEmail={initialEmail}
                      officeContactPhone={officeContactPhone}
                      readOnly={readOnlyEmail}
                    />
                  </Grid>
                )}

                {!readOnlyPhoneFields && (
                  <>
                    <Grid item xs={6}>
                      <FormikPhoneField
                        fullWidth
                        label={t("mobile_phone")}
                        name="mobilePhone"
                        required={!!values.consentsToText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikPhoneField fullWidth label={t("home_phone_label")} name="homePhone" />
                    </Grid>
                    {hideWorkPhone || (
                      <Grid item xs={6}>
                        <FormikPhoneField fullWidth label={t("work_phone")} name="workPhone" />
                      </Grid>
                    )}
                    {consentsToTextProvided && !consentsToText && (
                      <Grid item xs={12}>
                        <Box my={2}>
                          <ConsentsToTextCheckbox />
                        </Box>
                      </Grid>
                    )}
                  </>
                )}

                {!!preferredContactMethodOptions?.length && (
                  <Grid item xs={12}>
                    <PreferredContactMethod
                      hideWorkPhone={hideWorkPhone}
                      preferredContactMethodOptions={translatedPreferredContactMethodOptions}
                      readOnlyPhoneFields={readOnlyPhoneFields}
                    />
                  </Grid>
                )}
              </SpacedGrid>
            </OneColumnGrid>
          </Page.Content>
          <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} />
        </Page>
      )}
    </Formik>
  );
};

export default ContactInfoForm;
