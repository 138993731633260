import useTranslation from "hooks/useTranslation";

const DayHours = ({ daySchedule: { twentyFourHours, closedAllDay, open, close } }) => {
  const t = useTranslation("universal.search_results");

  if (twentyFourHours) {
    return t("open_24_hours");
  }

  if (closedAllDay) {
    return t("closed");
  }

  if (open && close) {
    return `${open} - ${close}`;
  }

  return t("closed");
};

export default DayHours;
