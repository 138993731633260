import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const QuickConfirmRowWithLabel = ({ label, value, ...rest }) => (
  <Typography align="center" sx={{ mb: 1 }} variant="body1" {...rest}>
    <Box component="span" fontWeight="fontWeightBold">{`${label}: `}</Box>
    <Box color="textPrimary" component="span">
      {value}
    </Box>
  </Typography>
);

export default QuickConfirmRowWithLabel;
