import ErrorScreen from "components/ErrorScreen";
import { PropTypes } from "prop-types";
import useTranslation from "hooks/useTranslation";

const ErrorPage = ({ variant }) => {
  const t = useTranslation(`previsit.two_factor_auth.error_page.${variant}`);

  return <ErrorScreen description={t("description")} icon="⚠️" title={t("title")} />;
};

export default ErrorPage;

ErrorPage.propTypes = {
  variant: PropTypes.oneOf(["session_expired", "check_in_locked"]).isRequired,
};
