import { useMediaQuery, useTheme } from "@mui/material";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Input = ({ onChange, question, readOnly, inputTypes, value }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return inputTypes?.mobile && mdDown ? (
    <Mobile
      onChange={onChange}
      question={question}
      readOnly={readOnly}
      type={question.inputType?.mobile || inputTypes.mobile}
      value={value}
    />
  ) : (
    <Desktop
      onChange={onChange}
      question={question}
      readOnly={readOnly}
      type={question.inputType?.desktop || inputTypes?.desktop}
      value={value}
    />
  );
};

export default Input;
