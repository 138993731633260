import Page from "components/Page";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import FormikSelect from "components/FormikControls/FormikSelect";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import useTranslation from "hooks/useTranslation";
import validationSchema from "./validationSchema";

const View = ({ isSubmitting, onSubmit, referralSources }) => {
  const t = useTranslation("universal.personal_information.more_info");

  return (
    <Formik initialValues={{ referralsourceid: "" }} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Page>
        <Page.Form formik />
        <Page.Title>{t("title")}</Page.Title>

        <Page.Content>
          <OneColumnGrid>
            <SpacedGrid>
              <Grid item xs={12}>
                <FormikSelect
                  emptyOption={t("select")}
                  label={t("label")}
                  name="referralsourceid"
                  options={referralSources}
                  required
                />
              </Grid>
            </SpacedGrid>
          </OneColumnGrid>
        </Page.Content>

        <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} />

        <Page.RequiredCopy />
      </Page>
    </Formik>
  );
};

export default View;

View.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  referralSources: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};
