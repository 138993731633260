import * as yup from "yup";
import initTranslationsHelper from "utils/initTranslationsHelper";
import getEnv from "utils/getEnv";

const regexes = getEnv("regexes");
const translate = initTranslationsHelper("universal.personal_information.location");

const validationSchema = yup.object().shape({
  address1: yup.string().ensure().max(100).required(),
  address2: yup.string().ensure().max(100),
  city: yup
    .string()
    .ensure()
    .max(30)
    .matches(/^[^0-9]*$/, translate("error_alphabetic"))
    .required(),
  state: yup.string().max(10).required(),
  zip: yup
    .string()
    .ensure()
    .required()
    .matches(regexes.zip_code_regex, {
      message: translate("error_zip"),
    })
    .max(10),
});

export default validationSchema;
