import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormikSelect from "components/FormikControls/FormikSelect";
import { useFormikContext } from "formik";
import t from "./translate";
import inferContactMethod, { CONTACT_METHODS } from "./inferContactMethod";

const PreferredContactMethod = ({
  hideWorkPhone,
  preferredContactMethodOptions: allPreferredContactMethodOptions,
  readOnlyPhoneFields,
}) => {
  const preferredContactMethodOptions = usePreferredContactMethodOptions({
    allPreferredContactMethodOptions,
    hideWorkPhone,
    readOnlyPhoneFields,
  });

  return (
    <Box>
      <FormikSelect
        label={t("preferred_contact_method_label")}
        name="preferredContactMethod"
        options={preferredContactMethodOptions}
        required
      />
      {readOnlyPhoneFields && <Typography variant="caption">{t("read_only_phones_hint")}</Typography>}
    </Box>
  );
};

export default PreferredContactMethod;

const usePreferredContactMethodOptions = ({ allPreferredContactMethodOptions, hideWorkPhone, readOnlyPhoneFields }) => {
  const { values } = useFormikContext();
  const phones = {
    [CONTACT_METHODS.mobilePhone]: values.mobilePhone,
    [CONTACT_METHODS.homePhone]: values.homePhone,
    [CONTACT_METHODS.workPhone]: values.workPhone,
  };

  return allPreferredContactMethodOptions.filter((option) => {
    const contactMethod = inferContactMethod(option.value);

    if (readOnlyPhoneFields && contactMethod && !phones[contactMethod]) {
      return false;
    }
    if (hideWorkPhone && contactMethod === CONTACT_METHODS.workPhone) {
      return false;
    }

    return true;
  });
};
