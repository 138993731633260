import styles from "./styles.module.scss";

const SwipeCreditCardSvg = () => (
  <svg className={styles.svg} viewBox="0 0 262.7 359.36" xmlns="http://www.w3.org/2000/svg">
    <title>swipe card image</title>
    <g data-name="Layer 1" id="Layer_1">
      <path
        className="cls-1"
        d="M283,424.13H130.46c-6.94,0-12.16-10.76-12.16-14V157.38c0-6.63,6.88-15.24,15.31-15.24H284.36c6.93,0,13.94,8.12,13.94,13.93V305.22c0,30.07,0,85.24,0,103.61C298.29,415.49,291.4,424.13,283,424.13Z"
        transform="translate(-118.3 -142.14)"
      />
      <g data-name="Layer 2" id="Layer_2">
        <path
          className="cls-1"
          d="M289.29,352.69c0,9.69-8.05,16.46-17.7,16.46H143.88c-9.64,0-16.57-6.6-16.57-16.29,0,0,.13-38.77.13-69.84h0V166.49c0-9.7,5.53-15.2,15.18-15.2l44.65-.15H228.8s40.93.15,45.32.15,15.17,1.12,15.17,15.2Z"
          transform="translate(-118.3 -142.14)"
        />
      </g>
    </g>
    <g data-name="Layer 8" id="Layer_8">
      <path
        className="cls-2"
        d="M273.8,373.5H377.21a3.79,3.79,0,0,1,3.79,3.8v64.12c0,2.1-1.69,5.07-3.79,5.07H275.06c-2.09,0-5.05-3-5.05-5.07V377.3A3.8,3.8,0,0,1,273.8,373.5Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-3"
        d="M363.46,422.5a6,6,0,0,0-4.5,2,6,6,0,1,0,0,7.92,6,6,0,1,0,4.5-10Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 9" id="Layer_9">
      <path
        className="cls-4"
        d="M373.7,379.49a1.79,1.79,0,0,1,1.8,1.78v57.45a1.79,1.79,0,0,1-1.8,1.78H277.32a1.8,1.8,0,0,1-1.81-1.78V381.27a1.8,1.8,0,0,1,1.81-1.78H373.7m0-2H277.32a3.8,3.8,0,0,0-3.81,3.78v57.45a3.8,3.8,0,0,0,3.81,3.78H373.7a3.79,3.79,0,0,0,3.8-3.78V381.27a3.79,3.79,0,0,0-3.8-3.78Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M286.34,410.9h.57v1h-.57v1.21H285v-1.21h-2.2l-.08-.81,2.28-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M291.46,413.13h-4v-.86l1.83-1.93a2.19,2.19,0,0,0,.68-1.22.91.91,0,0,0-.16-.56.58.58,0,0,0-.47-.19.59.59,0,0,0-.49.26,1.08,1.08,0,0,0-.19.64h-1.32a1.82,1.82,0,0,1,.27-1,1.71,1.71,0,0,1,.72-.69,2.47,2.47,0,0,1,2.46.17,1.53,1.53,0,0,1,.5,1.22,1.65,1.65,0,0,1-.13.66,3.11,3.11,0,0,1-.38.67,11,11,0,0,1-.85.93l-.74.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M295.6,410.9h.58v1h-.58v1.21h-1.32v-1.21h-2.19l-.08-.81,2.27-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M300.73,413.13h-4v-.86l1.83-1.93a2.16,2.16,0,0,0,.67-1.22.85.85,0,0,0-.16-.56.56.56,0,0,0-.46-.19.58.58,0,0,0-.49.26,1,1,0,0,0-.19.64h-1.32a1.9,1.9,0,0,1,.26-1,1.8,1.8,0,0,1,.73-.69,2.11,2.11,0,0,1,1-.25,2.15,2.15,0,0,1,1.42.42,1.53,1.53,0,0,1,.5,1.22,1.85,1.85,0,0,1-.13.66,2.81,2.81,0,0,1-.39.67,9.48,9.48,0,0,1-.85.93l-.73.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M308.85,410.9h.58v1h-.58v1.21h-1.32v-1.21h-2.19l-.08-.81,2.27-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M314,413.13h-4v-.86l1.83-1.93a2.16,2.16,0,0,0,.67-1.22.85.85,0,0,0-.16-.56.56.56,0,0,0-.46-.19.58.58,0,0,0-.49.26,1,1,0,0,0-.19.64h-1.32a1.9,1.9,0,0,1,.26-1,1.8,1.8,0,0,1,.73-.69,2.11,2.11,0,0,1,1-.25,2.15,2.15,0,0,1,1.42.42,1.53,1.53,0,0,1,.5,1.22,1.85,1.85,0,0,1-.13.66,2.81,2.81,0,0,1-.39.67,9.48,9.48,0,0,1-.85.93l-.73.85H314Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M318.12,410.9h.57v1h-.57v1.21H316.8v-1.21h-2.2l-.08-.81,2.28-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M323.24,413.13h-4v-.86l1.83-1.93a2.19,2.19,0,0,0,.68-1.22.91.91,0,0,0-.16-.56.58.58,0,0,0-.47-.19.59.59,0,0,0-.49.26,1.08,1.08,0,0,0-.18.64h-1.32a1.81,1.81,0,0,1,.26-1,1.71,1.71,0,0,1,.72-.69,2.47,2.47,0,0,1,2.46.17,1.53,1.53,0,0,1,.5,1.22,1.84,1.84,0,0,1-.12.66,3.7,3.7,0,0,1-.39.67,11,11,0,0,1-.85.93l-.74.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M331.37,410.9h.57v1h-.57v1.21h-1.32v-1.21h-2.2l-.08-.81,2.28-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M336.49,413.13h-4v-.86l1.83-1.93a2.19,2.19,0,0,0,.68-1.22.91.91,0,0,0-.16-.56.58.58,0,0,0-.47-.19.59.59,0,0,0-.49.26,1.08,1.08,0,0,0-.18.64h-1.32a1.81,1.81,0,0,1,.26-1,1.71,1.71,0,0,1,.72-.69,2.47,2.47,0,0,1,2.46.17,1.53,1.53,0,0,1,.5,1.22,1.84,1.84,0,0,1-.12.66,3.7,3.7,0,0,1-.39.67,11,11,0,0,1-.85.93l-.74.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M340.63,410.9h.58v1h-.58v1.21h-1.31v-1.21h-2.2l-.08-.81,2.28-3.66h1.31Zm-2.34,0h1v-1.75l-.09.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M345.76,413.13h-4v-.86l1.83-1.93a2.16,2.16,0,0,0,.67-1.22.85.85,0,0,0-.16-.56.55.55,0,0,0-.46-.19.58.58,0,0,0-.49.26,1,1,0,0,0-.19.64h-1.32a1.9,1.9,0,0,1,.26-1,1.8,1.8,0,0,1,.73-.69,2.47,2.47,0,0,1,2.46.17,1.53,1.53,0,0,1,.5,1.22,1.85,1.85,0,0,1-.13.66,2.81,2.81,0,0,1-.39.67,9.48,9.48,0,0,1-.85.93l-.73.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M353.88,410.9h.58v1h-.58v1.21h-1.31v-1.21h-2.2l-.08-.81,2.28-3.66h1.31Zm-2.34,0h1v-1.75l-.09.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M359,413.13h-4v-.86l1.83-1.93a2.16,2.16,0,0,0,.67-1.22.85.85,0,0,0-.16-.56.55.55,0,0,0-.46-.19.58.58,0,0,0-.49.26,1,1,0,0,0-.19.64h-1.32a1.9,1.9,0,0,1,.26-1,1.8,1.8,0,0,1,.73-.69,2.47,2.47,0,0,1,2.46.17,1.53,1.53,0,0,1,.5,1.22,1.85,1.85,0,0,1-.13.66,2.81,2.81,0,0,1-.39.67,9.48,9.48,0,0,1-.85.93l-.73.85H359Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M363.15,410.9h.58v1h-.58v1.21h-1.32v-1.21h-2.2l-.08-.81,2.28-3.66h1.32Zm-2.34,0h1v-1.75l-.08.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M368.27,413.13h-4v-.86l1.83-1.93a2.19,2.19,0,0,0,.68-1.22.91.91,0,0,0-.16-.56.57.57,0,0,0-.47-.19.59.59,0,0,0-.49.26,1.08,1.08,0,0,0-.18.64h-1.32a1.81,1.81,0,0,1,.26-1,1.74,1.74,0,0,1,.73-.69,2.07,2.07,0,0,1,1-.25,2.13,2.13,0,0,1,1.42.42,1.5,1.5,0,0,1,.5,1.22,1.84,1.84,0,0,1-.12.66,3.7,3.7,0,0,1-.39.67,11,11,0,0,1-.85.93l-.74.85h2.24Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M294.8,387.49a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1h-10a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h10m0-2h-10a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h10a3,3,0,0,0,3-3v-6a3,3,0,0,0-3-3Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M286.11,425.06h1.71v4.88a2.35,2.35,0,0,1-.32,1.21,2.15,2.15,0,0,1-.87.82,2.66,2.66,0,0,1-1.26.29,2.59,2.59,0,0,1-1.81-.58,2.13,2.13,0,0,1-.64-1.66h1.72a1.22,1.22,0,0,0,.16.71.7.7,0,0,0,.57.22.63.63,0,0,0,.55-.27,1.25,1.25,0,0,0,.19-.74Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M288.62,429.48a3.18,3.18,0,0,1,.3-1.41,2.21,2.21,0,0,1,.89-.95,2.65,2.65,0,0,1,1.35-.33,2.43,2.43,0,0,1,1.87.73,2.81,2.81,0,0,1,.68,2v.06a2.76,2.76,0,0,1-.68,2,2.77,2.77,0,0,1-3.67,0,2.65,2.65,0,0,1-.74-1.84Zm1.64.1a2,2,0,0,0,.23,1.07.76.76,0,0,0,.68.35c.58,0,.88-.46.9-1.36v-.16c0-1-.31-1.42-.91-1.42s-.85.4-.89,1.22Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M296.05,427.45a1.81,1.81,0,0,1,2.66-.15,2.35,2.35,0,0,1,.46,1.53v3.34h-1.65v-3.3a1,1,0,0,0-.17-.62.76.76,0,0,0-.59-.19.77.77,0,0,0-.71.32v3.79H294.4v-7.5h1.65Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M301.59,426.88l.06.62a1.79,1.79,0,0,1,1.51-.71,1.49,1.49,0,0,1,1.23.49,2.35,2.35,0,0,1,.42,1.48v3.41h-1.65v-3.34a.9.9,0,0,0-.16-.59.77.77,0,0,0-.59-.18.76.76,0,0,0-.71.38v3.73h-1.65v-5.29Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M314.32,428.76a4.16,4.16,0,0,1-.33,1.72,3.07,3.07,0,0,1-.9,1.18l1.15.91-1.06.9-1.53-1.23-.43,0a3,3,0,0,1-1.61-.42,2.85,2.85,0,0,1-1.1-1.2,4.1,4.1,0,0,1-.41-1.79v-.38a4.12,4.12,0,0,1,.39-1.84,2.81,2.81,0,0,1,1.09-1.24,3.05,3.05,0,0,1,1.63-.43,3,3,0,0,1,1.61.43,2.9,2.9,0,0,1,1.1,1.22,4.09,4.09,0,0,1,.4,1.82Zm-1.74-.3a3,3,0,0,0-.36-1.61,1.22,1.22,0,0,0-2,0,3,3,0,0,0-.35,1.59v.33a3.08,3.08,0,0,0,.35,1.61,1.21,1.21,0,0,0,2,0,3.12,3.12,0,0,0,.36-1.59Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M316.13,430.57a.94.94,0,0,1,.65.23.81.81,0,0,1,0,1.19.94.94,0,0,1-.65.23,1,1,0,0,1-.65-.23.83.83,0,0,1,0-1.19A1,1,0,0,1,316.13,430.57Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M320.71,432.17v-7.11H323a3.32,3.32,0,0,1,1.69.43,2.94,2.94,0,0,1,1.17,1.2,3.52,3.52,0,0,1,.43,1.75v.32a3.68,3.68,0,0,1-.41,1.75,3.1,3.1,0,0,1-1.16,1.22,3.39,3.39,0,0,1-1.68.44Zm1.71-5.79v4.47H323a1.35,1.35,0,0,0,1.14-.53,2.6,2.6,0,0,0,.39-1.56v-.3a2.6,2.6,0,0,0-.39-1.56,1.36,1.36,0,0,0-1.15-.52Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M326.89,429.48a3.18,3.18,0,0,1,.3-1.41,2.21,2.21,0,0,1,.89-.95,2.65,2.65,0,0,1,1.35-.33,2.43,2.43,0,0,1,1.87.73,2.81,2.81,0,0,1,.68,2v.06a2.72,2.72,0,0,1-.68,2,2.77,2.77,0,0,1-3.67,0,2.65,2.65,0,0,1-.74-1.84Zm1.64.1a2,2,0,0,0,.23,1.07.78.78,0,0,0,.68.35c.59,0,.89-.46.9-1.36v-.16c0-1-.3-1.42-.91-1.42s-.85.4-.89,1.22Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M335.27,432.26a2.73,2.73,0,0,1-2-.72,2.5,2.5,0,0,1-.75-1.89v-.13a3.31,3.31,0,0,1,.3-1.44,2.17,2.17,0,0,1,.87-.95,2.63,2.63,0,0,1,1.37-.34,2.3,2.3,0,0,1,1.76.69,2.7,2.7,0,0,1,.64,1.92V430h-3.27a1.19,1.19,0,0,0,.39.7,1.12,1.12,0,0,0,.77.26,1.49,1.49,0,0,0,1.22-.55l.75.89a2,2,0,0,1-.87.67A2.91,2.91,0,0,1,335.27,432.26Zm-.19-4.2c-.48,0-.77.32-.86.95h1.66v-.12a.72.72,0,0,0-.8-.83Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 4" id="Layer_4">
      <path
        className="cls-5"
        d="M276.1,402.14H140.5c0-14.61-2.88-20.94-16.7-20.94V240.49H124c-.11-26-.19-53.24-.19-69.2,0-6.61,6.75-15.15,15.14-15.15h140c10.36,0,13.87,10,13.87,12.63V381.2C285.52,381.2,276.1,382.88,276.1,402.14Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 3" id="Layer_3">
      <path
        className="cls-6"
        d="M270.1,393.14H146.5c0-14.61-8.88-18.94-22.7-18.94V231.49H124c-.11-26-.19-53.24-.19-69.2,0-6.61,6.75-15.15,15.14-15.15h140c10.36,0,13.87,10,13.87,12.63V374.2C285.52,374.2,270.1,373.88,270.1,393.14Z"
        transform="translate(-118.3 -142.14)"
      />
      <path className="cls-3" d="M281.8,390.5h16v2h-16Z" transform="translate(-118.3 -142.14)" />
      <path
        className="cls-4"
        d="M289.8,387.49c.82,0,2,1.56,2,4s-1.18,4-2,4-2-1.56-2-4,1.19-4,2-4m0-2c-2.2,0-4,2.69-4,6s1.8,6,4,6,4-2.68,4-6-1.79-6-4-6Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 5" id="Layer_5">
      <path
        className="cls-1"
        d="M289.29,352.69c0,9.69-8.05,16.46-17.7,16.46H143.88c-9.64,0-16.57-6.6-16.57-16.29,0,0,.13-38.77.13-69.84h0V166.49c0-9.7,5.53-15.2,15.18-15.2l44.65-.15H228.8s40.93.15,45.32.15,15.17,1.12,15.17,15.2Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-7"
        d="M279.8,336.26v17.68c0,2.56-3.77,6.32-6.31,6.32H143.12c-2.55,0-6.32-3.76-6.32-6.32V336.26h143m4-4h-151v21.68c0,4.73,5.59,10.32,10.32,10.32H273.49c4.72,0,10.31-5.59,10.31-10.32V332.26Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-8"
        d="M208.3,340.76a7.5,7.5,0,1,0,7.5,7.5A7.5,7.5,0,0,0,208.3,340.76Zm0,10a2.51,2.51,0,1,1,2.51-2.5A2.5,2.5,0,0,1,208.29,350.75Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 7" id="Layer_7">
      <path
        className="cls-9"
        d="M174.94,499.5a3.87,3.87,0,0,1-2.64-1l-22.69-19.55a3.91,3.91,0,0,1-.25-5.56l.19-.19,22.8-19.66a3.84,3.84,0,0,1,2.59-1c2.06,0,4.35,1.7,4.35,4v9h61a4.05,4.05,0,0,1,4,4v13a4.05,4.05,0,0,1-4,4h-61v9c0,2.29-2.3,4-4.34,4Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-9"
        d="M175,454.51a2.41,2.41,0,0,1,2.34,2v11h63a2.07,2.07,0,0,1,2,2v13a2.07,2.07,0,0,1-2,2h-63v11a2.42,2.42,0,0,1-2.34,2h0a1.87,1.87,0,0,1-1.28-.51l-22.74-19.6a1.9,1.9,0,0,1-.1-2.68l.1-.09L173.66,455a1.87,1.87,0,0,1,1.29-.51m0-4h0A5.91,5.91,0,0,0,171,452l-22.7,19.57-.11.1-.31.31a5.9,5.9,0,0,0,.31,8.32l.11.1L171,500a5.87,5.87,0,0,0,3.93,1.51c3,0,6.35-2.46,6.35-6v-7h59a6.08,6.08,0,0,0,6-6v-13a6.08,6.08,0,0,0-6-6h-59v-7c0-3.53-3.34-6-6.34-6Z"
        transform="translate(-118.3 -142.14)"
      />
      <rect className="cls-9" height="21" rx="4" ry="4" width="11" x="126.99" y="323.36" />
      <path
        className="cls-9"
        d="M252.29,467.5c1.38,0,2,.62,2,2v13c0,1.38-.62,2-2,2h-3c-1.38,0-2-.62-2-2v-13c0-1.38.62-2,2-2h3m0-4h-3a5.68,5.68,0,0,0-6,6v13a5.68,5.68,0,0,0,6,6h3a5.68,5.68,0,0,0,6-6v-13a5.68,5.68,0,0,0-6-6Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-2"
        d="M261.29,486.5a3.71,3.71,0,0,1-4-4v-13a3.71,3.71,0,0,1,4-4h3a3.82,3.82,0,0,1,4,4.16V482.5a3.71,3.71,0,0,1-4,4Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-9"
        d="M264.29,467.5c1.38,0,2,.78,2,2.16V482.5c0,1.38-.62,2-2,2h-3c-1.38,0-2-.62-2-2v-13c0-1.38.62-2,2-2h3m0-4h-3a5.68,5.68,0,0,0-6,6v13a5.68,5.68,0,0,0,6,6h3a5.68,5.68,0,0,0,6-6V469.66a5.82,5.82,0,0,0-6-6.16Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-9"
        d="M175,496.5a.9.9,0,0,1-.61-.24l-22.77-19.63a.9.9,0,0,1,0-1.24l22.76-19.62a.93.93,0,0,1,.64-.26c.61,0,1.34.61,1.34,1v11a1,1,0,0,0,1,1h63a1.08,1.08,0,0,1,1,1v13a1.08,1.08,0,0,1-1,1h-63a1,1,0,0,0-1,1v11C176.29,495.88,175.56,496.5,175,496.5Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M175,456.52a.7.7,0,0,1,.31.18v10.8a2,2,0,0,0,2,2h63a.12.12,0,0,1,0,.05v12.9a.12.12,0,0,1,0,.05h-63a2,2,0,0,0-2,2v10.81a.9.9,0,0,1-.31.18h0L152.37,476,175,456.53h0m0-2a1.87,1.87,0,0,0-1.29.51l-22.74,19.6-.1.09a1.9,1.9,0,0,0,.1,2.68L173.66,497a1.87,1.87,0,0,0,1.28.51h0a2.42,2.42,0,0,0,2.34-2v-11h63a2.07,2.07,0,0,0,2-2v-13a2.07,2.07,0,0,0-2-2h-63v-11a2.41,2.41,0,0,0-2.34-2Z"
        transform="translate(-118.3 -142.14)"
      />
      <rect className="cls-9" height="15" rx="1" ry="1" width="5" x="129.99" y="326.36" />
      <path
        className="cls-4"
        d="M252.29,469.5v13h-3v-13h3m0-2h-3c-1.38,0-2,.62-2,2v13c0,1.38.62,2,2,2h3c1.38,0,2-.62,2-2v-13c0-1.38-.62-2-2-2Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-2"
        d="M261.29,483.5c-.83,0-1-.17-1-1v-13c0-.83.17-1,1-1h3c.68,0,1,.17,1,1.16V482.5c0,.83-.17,1-1,1Z"
        transform="translate(-118.3 -142.14)"
      />
      <path
        className="cls-4"
        d="M264.29,469.5v13h-3v-13h3m0-2h-3c-1.38,0-2,.62-2,2v13c0,1.38.62,2,2,2h3c1.38,0,2-.62,2-2V469.66c0-1.38-.62-2.16-2-2.16Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
    <g data-name="Layer 6" id="Layer_6">
      <path
        className="cls-7"
        d="M276,160.27a3.79,3.79,0,0,1,3.79,3.79v159.2h-143V164.06a3.8,3.8,0,0,1,3.8-3.79H276m0-4H140.6a7.8,7.8,0,0,0-7.8,7.79v163.2h151V164.06a7.79,7.79,0,0,0-7.79-7.79Z"
        transform="translate(-118.3 -142.14)"
      />
    </g>
  </svg>
);

export default SwipeCreditCardSvg;
