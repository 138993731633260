import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Bold from "components/Typography/Bold";
import Page from "components/Page";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikSsnField from "components/FormikControls/FormikSsnField";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { translateMaritalStatusOptions } from "utils/translateOptions";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import getValidationSchema from "./getValidationSchema";

const t = initTranslationsHelper("universal.personal_information.general_info");

const Form = ({
  isSubmitting,
  maritalStatus,
  maritalStatusEnabled,
  maritalStatusOptions,
  onSubmit,
  preferredName,
  preferredNameEnabled,
  ssn,
  ssnEnabled,
  ssnReadOnly,
}) => {
  const translatedMaritalStatusOptions = translateMaritalStatusOptions(maritalStatusOptions);

  return (
    <Formik
      initialValues={{ maritalStatus, preferredName, ssn }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={getValidationSchema(maritalStatusEnabled)}
    >
      <Page>
        <Page.Form formik />
        <Page.Title>{t("title")}</Page.Title>

        <Page.Content>
          <OneColumnGrid>
            <SpacedGrid direction="column">
              {preferredNameEnabled && (
                <Grid item xs>
                  <FormikTextField
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    label={t("labels.preferred_name")}
                    name="preferredName"
                  />
                </Grid>
              )}
              {ssnEnabled && (
                <Grid item xs>
                  {ssnReadOnly ? (
                    <>
                      <Typography variant="body1">
                        <Bold>{t("read_only_ssn.label", { ssn })}</Bold>
                      </Typography>
                      <Typography variant="body2">{t("read_only_ssn.hint")}</Typography>
                    </>
                  ) : (
                    <FormikSsnField fullWidth label={t("labels.ssn")} name="ssn" />
                  )}
                </Grid>
              )}
              {maritalStatusEnabled && (
                <Grid item xs>
                  <FormikSelect
                    emptyOption=""
                    label={t("labels.marital_status")}
                    name="maritalStatus"
                    options={translatedMaritalStatusOptions}
                    required
                  />
                </Grid>
              )}
            </SpacedGrid>
          </OneColumnGrid>
        </Page.Content>
        {maritalStatusEnabled && <Page.RequiredCopy />}
        <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting}>
          {t("save_button")}
        </Page.ButtonPrimary>
      </Page>
    </Formik>
  );
};

export default Form;
