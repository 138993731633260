import { useMemo } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import Page from "components/Page";
import {
  translateOccupationOptions,
  translateLanguageOptions,
  translateRaceOptions,
  translateEthnicityOptions,
} from "utils/translateOptions";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import FormikTextField from "components/FormikControls/FormikTextField";
import OccupationSelect from "./OccupationSelect";
import LanguageSelect from "./LanguageSelect";
import EthnicitySelect from "./EthnicitySelect";
import RaceSelect from "./RaceSelect";
import getValidationSchema from "./getValidationSchema";
import t from "./translate";
import { isString } from "lodash";

const View = ({
  ethnicity,
  ethnicityOptions,
  ethnicityQuestionEnabled,
  ethnicityRatherNotSayOption,
  isSubmitting,
  language,
  languageOptions,
  languageRatherNotSayOption,
  occupation,
  occupationOptions,
  occupationQuestionEnabled,
  onSubmit,
  raceOptions,
  raceQuestionEnabled,
  raceRatherNotSayOption,
  races,
}) => {
  const multipleEthnicities = !isString(ethnicity);
  const ratherNotSayOptionsPresent =
    raceRatherNotSayOption && ethnicityRatherNotSayOption && languageRatherNotSayOption;
  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        raceQuestionEnabled,
        ethnicityQuestionEnabled,
        ratherNotSayOptionsPresent,
        multipleEthnicities,
      }),
    [ethnicityQuestionEnabled, raceQuestionEnabled, ratherNotSayOptionsPresent, multipleEthnicities],
  );
  const translatedOccupationOptions = translateOccupationOptions(occupationOptions);
  const translatedLanguageOptions = translateLanguageOptions(languageOptions);
  const translatedRaceOptions = translateRaceOptions(raceOptions);
  const translatedEthnicityOptions = translateEthnicityOptions(ethnicityOptions);

  const initialValues = () => {
    if (ratherNotSayOptionsPresent) {
      const raceRatherNotSay = races.includes(raceRatherNotSayOption.value);
      const ethnicityRatherNotSay = ethnicity.includes(ethnicityRatherNotSayOption.value);
      const languageRatherNotSay = language === languageRatherNotSayOption.value;

      return {
        ethnicity: ethnicityRatherNotSay ? [] : ethnicity,
        ethnicityRatherNotSay,
        language: languageRatherNotSay ? "" : language,
        languageRatherNotSay,
        occupation,
        raceRatherNotSay,
        races: raceRatherNotSay ? [] : races,
      };
    } else {
      return {
        ethnicity,
        language,
        occupation,
        races,
      };
    }
  };

  return (
    <Formik initialValues={initialValues()} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Page>
        <Page.Form formik />
        <Page.Title>{t("title")}</Page.Title>
        <Page.Content>
          <OneColumnGrid>
            <SpacedGrid>
              {occupationQuestionEnabled && (
                <Grid item xs={12}>
                  {occupationOptions.length === 0 ? (
                    <FormikTextField fullWidth label={t("occupation_label")} name="occupation" />
                  ) : (
                    <OccupationSelect occupationOptions={translatedOccupationOptions} />
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <LanguageSelect
                  languageOptions={translatedLanguageOptions}
                  ratherNotSayOptionsPresent={ratherNotSayOptionsPresent}
                />
              </Grid>

              {ethnicityQuestionEnabled && (
                <Grid item xs={12}>
                  <EthnicitySelect
                    ethnicityOptions={translatedEthnicityOptions}
                    multiple={multipleEthnicities}
                    ratherNotSayOptionsPresent={ratherNotSayOptionsPresent}
                  />
                </Grid>
              )}
              {raceQuestionEnabled && (
                <Grid item xs={12}>
                  <RaceSelect
                    raceOptions={translatedRaceOptions}
                    ratherNotSayOptionsPresent={ratherNotSayOptionsPresent}
                  />
                </Grid>
              )}
            </SpacedGrid>
          </OneColumnGrid>
        </Page.Content>

        <Page.RequiredCopy />

        <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} />
      </Page>
    </Formik>
  );
};

View.propTypes = {
  occupationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  occupation: PropTypes.string,
  occupationQuestionEnabled: PropTypes.bool.isRequired,

  raceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  races: PropTypes.arrayOf(PropTypes.string),
  raceQuestionEnabled: PropTypes.bool,
  raceRatherNotSayOption: PropTypes.object,

  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  language: PropTypes.string,
  languageRatherNotSayOption: PropTypes.object,

  ethnicityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      groupName: PropTypes.string,
    }),
  ).isRequired,
  ethnicity: PropTypes.arrayOf(PropTypes.string),
  ethnicityQuestionEnabled: PropTypes.bool,
  ethnicityRatherNotSayOption: PropTypes.object,

  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {
  ethnicity: [],
  ethnicityQuestionEnabled: false,
  language: "",
  occupation: "",
  occupationOptions: [],
  raceQuestionEnabled: false,
  races: [],
};

export default View;
