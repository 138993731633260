import clsx from "clsx";
import Loader from "components/Loader";
import styles from "./styles.module.scss";

const FullscreenSpinner = ({ visible }) => (
  <div aria-label="Loading" className={clsx(styles.root, { [styles.visible]: visible })}>
    <Loader />
  </div>
);

export default FullscreenSpinner;
