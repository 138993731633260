import { useState } from "react";
import { Typography } from "@mui/material";
import getEnv from "utils/getEnv";
import useTranslation from "hooks/useTranslation";
import FormikCheckboxLine from "components/FormikControls/FormikCheckboxLine";
import Link from "@mui/material/Link";
import Modal from "components/Modal";

const practiceName = getEnv("displayName");

const ConsentsToTextCheckbox = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const t = useTranslation("universal.sms_terms_and_conditions");

  return (
    <>
      <FormikCheckboxLine
        label={
          <Typography variant="body1">
            {t("text_link_part_1")}
            <Link component="button" onClick={() => setTermsModalOpen(true)} type="button" variant="body1">
              {t("text_link_part_2")}
            </Link>
            {t("text_link_part_3", { practice: practiceName })}
          </Typography>
        }
        leftAlign
        name="consentsToText"
        value="true"
      />

      <Modal
        doneButtonText={t("done")}
        onDoneButtonClick={() => setTermsModalOpen(false)}
        open={termsModalOpen}
        title={t("title")}
        wide
      >
        <Typography variant="body1">{t("disclaimer")}</Typography>
      </Modal>
    </>
  );
};

export default ConsentsToTextCheckbox;
