import requestToServer from "utils/requestToServer";
import getRenderedFormFromSchema from "./getRenderedFormFromSchema";

function saveCustomForm({ action, customFormId, method, schemaToSave }) {
  const formData = {
    custom_form_id: customFormId,
    saved_custom_form: {
      html_data: getRenderedFormFromSchema(schemaToSave),
      json_data: JSON.stringify(schemaToSave.controls),
    },
  };
  requestToServer({ path: action, body: formData, method });
}

export default saveCustomForm;
