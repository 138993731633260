import PropTypes from "prop-types";
import { useField } from "formik";
import SelectMultiple from "components/SelectMultiple";

const FormikSelectMultiple = ({ name, ...rest }) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  return <SelectMultiple error={touched && error} onChange={setValue} values={value} {...rest} />;
};

FormikSelectMultiple.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikSelectMultiple;
