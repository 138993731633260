import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import initTranslationsHelper from "utils/initTranslationsHelper";
import QuickConfirm from "components/QuickConfirm";

const translate = initTranslationsHelper("universal.primary_care_provider");

const QuickConfirmScreen = ({
  address1,
  address2,
  city,
  name,
  onNoChange,
  onNoPcp,
  onUpdate,
  pcpOnFile,
  phone,
  state,
  zip,
}) => {
  const theme = useTheme();

  return (
    <QuickConfirm
      icon={theme.icons.primaryCareProvider}
      itemList={
        pcpOnFile && (
          <Typography color="textPrimary" component="div" variant="body1">
            <Line>{name}</Line>
            {phone && <Line>{phone}</Line>}
            {address1 && <Line>{address1}</Line>}
            {address2 && <Line>{address2}</Line>}
            {city && <Line>{`${city}, ${state}, ${zip}`}</Line>}
          </Typography>
        )
      }
      message={translate(pcpOnFile ? "is_your_pcp_up_to_date" : "do_you_have_a_pcp")}
      onPrimaryButtonClick={pcpOnFile ? onNoChange : onUpdate}
      onSecondaryButtonClick={pcpOnFile ? onUpdate : onNoPcp}
      primaryButtonText={translate(pcpOnFile ? "no_change" : "yes_button")}
      secondaryButtonText={translate(pcpOnFile ? "update_button" : "no_button")}
    />
  );
};

const Line = ({ children }) => (
  <Box component="p" textAlign="center">
    {children}
  </Box>
);

export default QuickConfirmScreen;

QuickConfirmScreen.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  name: PropTypes.string,
  onNoChange: PropTypes.func.isRequired,
  onNoPcp: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  pcpOnFile: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
};

QuickConfirmScreen.defaultProps = {
  address1: undefined,
  address2: undefined,
  city: undefined,
  name: undefined,
  phone: undefined,
  state: undefined,
  zip: undefined,
};
