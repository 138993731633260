import { useState } from "react";
import omit from "lodash/omit";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import ContactInfo from "./index";

const Universal = ({
  action,
  method,
  data: {
    consentsToText,
    editEmailModal,
    email,
    emailConfirmationEnabled,
    emailProvided,
    hasNonIntegratedSmsConsent,
    hideEmail,
    hideWorkPhone,
    homePhone,
    mobilePhone,
    officeContactPhone,
    preferredContactMethod,
    preferredContactMethodOptions,
    readOnlyEmail,
    readOnlyPhoneFields,
    workPhone,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const submit = (data) => {
    setSubmitting(true);
    requestToServer({ body: transformKeysToSnakeCase(data), path: action, method });
  };
  const remapEmailProvided = ({ emailProvided: provideEmail, ...data }) => ({ provideEmail, ...data });

  const handleConfirm = () => submit({ quickConfirm: true });
  const handleSubmit = (values) =>
    submit({
      contactInfo: omit(remapEmailProvided(values), "emailConfirmation"),
    });

  return (
    <ContactInfo
      action
      consentsToText={consentsToText}
      editEmailModal={editEmailModal}
      email={email}
      emailConfirmationEnabled={emailConfirmationEnabled}
      emailProvided={emailProvided}
      hasNonIntegratedSmsConsent={hasNonIntegratedSmsConsent}
      hideEmail={hideEmail}
      hideWorkPhone={hideWorkPhone}
      homePhone={homePhone}
      isSubmitting={isSubmitting}
      method
      mobilePhone={mobilePhone}
      officeContactPhone={officeContactPhone}
      onConfirm={handleConfirm}
      onSubmit={handleSubmit}
      preferredContactMethod={preferredContactMethod}
      preferredContactMethodOptions={preferredContactMethodOptions}
      readOnlyEmail={readOnlyEmail}
      readOnlyPhoneFields={readOnlyPhoneFields}
      workPhone={workPhone}
    />
  );
};

export default Universal;
