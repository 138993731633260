import { useState } from "react";
import SignatureForm from "previsit/SignatureForm";
import requestToServer from "utils/requestToServer";
import formatCurrency from "utils/formatCurrency";
import t from "../../translate";

const RetailTransactionSignature = ({ submissionPath, amountToPay }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSave = (signatureData) => {
    setSubmitting(true);
    requestToServer({
      path: submissionPath,
      method: "PUT",
      body: {
        retail_transaction: {
          signature: JSON.stringify(signatureData.signature_attributes.drawing_instructions),
        },
      },
    });
  };

  return (
    <SignatureForm
      isSubmitting={isSubmitting}
      onSave={handleSave}
      title={t("thank_you_for_your_payment", {
        formattedAmount: formatCurrency(amountToPay),
      })}
    />
  );
};

export default RetailTransactionSignature;
