import { useState } from "react";
import { translatePatientRelationshipOptions } from "utils/translateOptions";
import GuarantorForm from "./GuarantorForm";
import GuarantorQuickConfirm from "./GuarantorQuickConfirm";
import getValidationSchema from "./getValidationSchema";
import dateMapper from "./dateMapper";

const Guarantor = ({
  address1,
  address2,
  addressSameAsPatient,
  city,
  dob,
  firstName,
  initialDay,
  initialMonth,
  initialYear,
  isSubmitting,
  lastName,
  monthOptions,
  onConfirm,
  onSubmit,
  patientsFullAddress,
  phone,
  relationshipOptions,
  relationshipSelfValue,
  relationshipToPatient,
  state,
  stateOptions,
  yearOptions,
  zip,
}) => {
  const isSelfRelationship = relationshipToPatient === relationshipSelfValue;
  const translatedRelationshipOptions = translatePatientRelationshipOptions(relationshipOptions);
  const validationSchema = getValidationSchema(relationshipSelfValue);
  const [year, month, day] = dateMapper.fromISODate(dob);
  const initialValues = {
    address1,
    address2,
    city,
    state,
    zip,
    firstName: isSelfRelationship ? "" : firstName,
    lastName: isSelfRelationship ? "" : lastName,
    phone: isSelfRelationship ? "" : phone,
    day: isSelfRelationship ? "" : day,
    month: isSelfRelationship ? "" : month,
    year: isSelfRelationship ? "" : year,
    addressSameAsPatient,
    relationshipToPatient,
  };
  const [showForm, setShowForm] = useState(() => !validationSchema.isValidSync(initialValues));

  const handleSubmit = (values) => {
    onSubmit(getValuesForSubmit(values));
  };

  const handleUpdate = () => {
    setShowForm(true);
  };

  return showForm ? (
    <GuarantorForm
      initialDay={initialDay}
      initialMonth={initialMonth}
      initialValues={initialValues}
      initialYear={initialYear}
      isSubmitting={isSubmitting}
      monthOptions={monthOptions}
      onSubmit={handleSubmit}
      patientsFullAddress={patientsFullAddress}
      relationshipOptions={translatedRelationshipOptions}
      relationshipSelfValue={relationshipSelfValue}
      stateOptions={stateOptions}
      validationSchema={validationSchema}
      yearOptions={yearOptions}
    />
  ) : (
    <GuarantorQuickConfirm
      address1={address1}
      address2={address2}
      city={city}
      dob={dob}
      firstName={firstName}
      isSelfRelationship={isSelfRelationship}
      lastName={lastName}
      onConfirm={onConfirm}
      onUpdate={handleUpdate}
      phone={phone}
      relationshipOptions={translatedRelationshipOptions}
      relationshipToPatient={relationshipToPatient}
      state={state}
      zip={zip}
    />
  );
};

export default Guarantor;

const getValuesForSubmit = (values) => {
  const { year, month, day, ...rest } = values;

  return {
    dob: dateMapper.toIsoDate(year, month, day),
    ...rest,
  };
};
