import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import styles from "./styles.module.scss";

const ToggleOption = ({ active, disabled, label, name, onChange, value }) => {
  const theme = useTheme();

  const id = `${name}_${value}`.toLowerCase();

  return (
    <label
      className={clsx(styles.label, { [styles.active]: active })}
      htmlFor={id}
      style={theme.components.ToggleOption?.style}
    >
      <input
        defaultChecked={active}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <Typography variant="button">{label}</Typography>
    </label>
  );
};

ToggleOption.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};

ToggleOption.defaultProps = {
  disabled: false,
  onChange: () => {},
};

export default ToggleOption;
