import useTranslation from "hooks/useTranslation";

const DayOfWeek = ({ daySchedule }) => {
  const t = useTranslation("universal.search_results");
  const translateCalendar = useTranslation("shared.calendar");

  const translateDayOfWeek = (dayValue, shortFormat) => {
    const daysOfWeekTranslations = translateCalendar(shortFormat ? "days_of_week_short" : "days_of_week");

    return daysOfWeekTranslations[dayValue] || dayValue;
  };

  if (daySchedule.everyDay) {
    return t("every_day");
  }

  const shortFormat = daySchedule.days.length > 1;
  return daySchedule.days.map((day) => translateDayOfWeek(day, shortFormat)).join(" - ");
};

export default DayOfWeek;
