import { useEffect, useRef } from "react";
import { useFormikContext, useField } from "formik";
import FormikTextField from "components/FormikControls/FormikTextField";
import SpacedGrid from "components/SpacedGrid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { PROVIDER_NAME_VALUE } from "components/PCPAndPharmacy/constants";

const t = initTranslationsHelper("universal.primary_care_provider.form");

const NameFields = () => {
  const [{ value: nameSearchBy }] = useField("nameSearchBy");
  const searchByProviderName = nameSearchBy === PROVIDER_NAME_VALUE;

  const { setFieldValue } = useFormikContext();
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (!firstRenderRef.current) {
      setFieldValue("firstNameOrGroupName", "");
      if (!searchByProviderName) setFieldValue("lastName", "");
      return;
    }

    firstRenderRef.current = false;
  }, [searchByProviderName, setFieldValue]);

  return (
    <SpacedGrid>
      <Grid item xs={searchByProviderName ? 6 : 12}>
        <FormikTextField
          fullWidth
          id="firstNameOrGroupName"
          label={t(searchByProviderName ? "first_name" : "group_or_office")}
          name="firstNameOrGroupName"
          required={!searchByProviderName}
        />
      </Grid>
      {searchByProviderName && (
        <>
          <Grid item xs={6}>
            <FormikTextField fullWidth id="lastName" label={t("last_name")} name="lastName" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" style={{ fontWeight: "bold" }} variant="body2">
              {t("hint")}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="body2">{t(`note_${searchByProviderName ? "provider" : "group_or_office"}`)}</Typography>
      </Grid>
    </SpacedGrid>
  );
};

export default NameFields;
