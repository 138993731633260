import { useTheme } from "@mui/material/styles";
import QuickConfirm from "components/QuickConfirm";
import QuickConfirmRow from "components/QuickConfirm/QuickConfirmRow";
import QuickConfirmRowWithLabel from "components/QuickConfirm/QuickConfirmRowWithLabel";
import { maskValue as formatPhone } from "components/PhoneTextField";
import toUSDateFormat from "utils/toUSDateFormat";
import t from "./translate";

const GuarantorQuickConfirm = ({
  address1,
  address2,
  city,
  dob,
  firstName,
  lastName,
  phone,
  state,
  zip,
  onConfirm,
  onUpdate,
  relationshipOptions,
  relationshipToPatient,
  isSelfRelationship,
}) => {
  const theme = useTheme();
  const relationshipLabel = relationshipOptions.find((option) => option.value === relationshipToPatient)?.label;
  const phoneFormatted = formatPhone(phone);
  const fullName = [firstName, lastName].filter(Boolean).join(" ");
  const address = [address1, address2].filter(Boolean).join(", ");
  const location = [city, state, zip].filter(Boolean).join(", ");

  return (
    <QuickConfirm
      icon={theme.icons.guarantor}
      itemList={
        <>
          <QuickConfirmRow value={fullName} />
          {dob && <QuickConfirmRowWithLabel label={t("guarantordob")} value={toUSDateFormat(dob, true)} />}
          <QuickConfirmRowWithLabel label={t("relationship_to_patient")} value={relationshipLabel} />
          {!isSelfRelationship && <QuickConfirmRow value={phoneFormatted} />}
          <QuickConfirmRow value={address} />
          <QuickConfirmRow value={location} />
        </>
      }
      message={t("billing_info_complete_and_up_to_date")}
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onUpdate}
      secondaryButtonText={t("update")}
    />
  );
};

export default GuarantorQuickConfirm;
