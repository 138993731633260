import { useMemo } from "react";
import mapValues from "lodash/mapValues";
import isNil from "lodash/isNil";
import * as Yup from "yup";
import { useFormik } from "formik";
import useErrorFocus from "components/FormikControls/useErrorFocus";

const useScreenerState = ({ form, onSubmit, questions, readOnly }) => {
  const formikConfig = useMemo(
    () =>
      getFormikConfig({
        questions,
        onSubmit,
        form,
        readOnly,
      }),
    [form, onSubmit, questions, readOnly],
  );

  const { values, errors, touched, handleSubmit, setFieldValue, isSubmitting, isValidating } = useFormik(formikConfig);

  useErrorFocus({
    errors,
    isSubmitting,
    isValidating,
    resolveErrorSelector: (field) => `[name=${field}]`,
  });

  const handleChange = (questionName, option) => setFieldValue(questionName, option.value);

  const errorsOfTouched = mapValues(touched, (isTouched, field) => isTouched && errors[field]);

  return {
    values,
    errors: errorsOfTouched,
    handleChange,
    handleSubmit,
    isSubmitting,
  };
};

export default useScreenerState;

const getFormikConfig = ({ questions, onSubmit, form, readOnly }) => {
  const [initialValues, objectSchema] = questions.reduce(
    ([initialValuesSoFar, objectSchemaSoFar], question) => {
      const { name, options, required } = question;

      let fieldValidationSchema = Yup.string().nullable();
      const initialValuesResult = { ...initialValuesSoFar };
      initialValuesResult[name] = form.initialValues[name];
      const questionValues = options.map(({ value }) => value);

      if (question.multiple) {
        fieldValidationSchema = Yup.array(Yup.string().oneOf(questionValues)).nullable();
      }

      if (questionValues.length && !question.multiple) {
        fieldValidationSchema = fieldValidationSchema.oneOf(questionValues);
      }

      if (question.validation?.number) {
        fieldValidationSchema = Yup.number().min(0);

        if (!isNil(question.validation.number?.min)) {
          fieldValidationSchema = fieldValidationSchema.min(question.validation.number.min);
        }

        if (!isNil(question.validation.number?.max)) {
          fieldValidationSchema = fieldValidationSchema.max(question.validation.number.max);
        }
      }

      if (required) {
        fieldValidationSchema = fieldValidationSchema.required();
      }

      return [
        initialValuesResult,
        {
          ...objectSchemaSoFar,
          [name]: fieldValidationSchema,
        },
      ];
    },
    [{}, {}],
  );

  return {
    initialValues,
    validationSchema: readOnly ? null : Yup.object(objectSchema),
    onSubmit,
  };
};
