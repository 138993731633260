import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import View from "./View";

const Universal = ({
  method,
  action,
  data: {
    commaSeparatedEmergencyContactEnabled,
    emergencyContactRequired,
    firstName,
    homePhone,
    lastName,
    mobilePhone,
    primaryPhone,
    primaryPhoneEnabled,
    name,
    relationship,
    relationshipEnabled,
    relationshipOptions,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);
    requestToServer({
      method,
      path: action,
      body: transformKeysToSnakeCase({
        emergencyContact: values,
      }),
    });
  };

  return (
    <View
      commaSeparatedEmergencyContactEnabled={commaSeparatedEmergencyContactEnabled}
      emergencyContactRequired={emergencyContactRequired}
      firstName={firstName}
      homePhone={homePhone}
      isSubmitting={isSubmitting}
      lastName={lastName}
      mobilePhone={mobilePhone}
      name={name}
      onSubmit={handleSubmit}
      primaryPhone={primaryPhone}
      primaryPhoneEnabled={primaryPhoneEnabled}
      relationship={relationship}
      relationshipEnabled={relationshipEnabled}
      relationshipOptions={relationshipOptions}
    />
  );
};

export default Universal;
