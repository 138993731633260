import * as Yup from "yup";
import initTranslationsHelper from "utils/initTranslationsHelper";
import getEnv from "utils/getEnv";

const regexes = getEnv("regexes");
const defaultTranslate = initTranslationsHelper("shared.validation");

const zipSchema = (translate) =>
  Yup.string().matches(regexes.zip_code_regex, { message: (translate || defaultTranslate)("error_zip") });

export default zipSchema;
