import * as Yup from "yup";
import isNanpPhoneNumberValid from "utils/isNanpPhoneNumberValid";
import initTranslationsHelper from "utils/initTranslationsHelper";

const defaultTranslate = initTranslationsHelper("shared.validation");

const phoneSchema = (translate) =>
  Yup.string()
    .ensure()
    .nullable()
    .max(20)
    .test("checkNanpNumber", (translate || defaultTranslate)("error_invalid_phone"), (value) => {
      const unprefixedValue = value.replace(/^\+1/, "");
      return isNanpPhoneNumberValid(unprefixedValue);
    });

export default phoneSchema;
