import initTranslationsHelper from "utils/initTranslationsHelper";
import Screen from "components/screeners/Screen";
import usePhq9ScreenerState from "./usePhq9ScreenerState";

const translate = initTranslationsHelper("universal.phq9_screeners");

const Phq9Screener = ({
  action,
  method,
  data: {
    alwaysShowAllQuestions,
    difficultyFollowupQuestion,
    form,
    inputTypes,
    oneColumnLayout,
    primaryQuestions,
    readOnly,
    screenerTypes,
    secondaryQuestions,
  },
}) => {
  const { visibleQuestions, onSubmit, errors, values, onQuestionChange } = usePhq9ScreenerState({
    action,
    alwaysShowAllQuestions,
    difficultyFollowupQuestion,
    form,
    method,
    primaryQuestions,
    readOnly,
    screenerTypes,
    secondaryQuestions,
  });

  return (
    <Screen
      description={translate("description")}
      errors={errors}
      inputTypes={inputTypes}
      onChange={onQuestionChange}
      onSubmit={onSubmit}
      oneColumnLayout={oneColumnLayout}
      questions={visibleQuestions}
      readOnly={readOnly}
      title={translate("title")}
      values={values}
    />
  );
};

export default Phq9Screener;
