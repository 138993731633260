import Page from "components/Page";
import formatCurrency from "utils/formatCurrency";
import ProcessingModal from "components/payments/ProcessingModal";
import useSwipeDetection from "hooks/cardReader/useSwipeDetection";
import SwipeCreditCard from "components/SwipeCreditCard";
import t from "../../../translate";

const SwipeScreen = ({
  amountToPay,
  processing,
  processingMessage,
  processingTitle,
  onSwipeDetected,
  onSwipeNotDetected,
  subtitle,
  takingTooLongMessage,
  takingTooLongTitle,
  title,
}) => {
  useSwipeDetection({
    onSwipeDetected,
    onSwipeNotDetected,
  });

  return (
    <>
      <Page>
        <Page.Layout maxWidth="none" />
        <Page.Title>{title || t("swipe_screen_title", { amount: formatCurrency(amountToPay) })}</Page.Title>
        <Page.Subtitle>{subtitle || t("payment_methods_text")}</Page.Subtitle>
        <Page.Content>
          <SwipeCreditCard />
        </Page.Content>
      </Page>
      <ProcessingModal
        open={processing}
        processingMessage={processingMessage || t("processing_description")}
        processingTitle={processingTitle}
        takingTooLongMessage={takingTooLongMessage || t("taking_too_long_description")}
        takingTooLongTitle={takingTooLongTitle}
      />
    </>
  );
};

export default SwipeScreen;
