import Box from "@mui/material/Box";
import useTranslation from "hooks/useTranslation";

const DayMealBreaks = ({ mealBreaks, showWeekMealBreaks }) => {
  const t = useTranslation("universal.search_results");

  const dayHasMealBreak = mealBreaks && mealBreaks.length;

  if (showWeekMealBreaks && dayHasMealBreak) {
    return "*";
  }

  if (dayHasMealBreak) {
    return (
      <>
        {mealBreaks.map((mealBreak) => {
          const mealBreakMessage = `(${t("closed")} ${mealBreak.startTime} - ${mealBreak.endTime})`;
          return <Box key={mealBreak.message}>{mealBreakMessage}</Box>;
        })}
      </>
    );
  }

  return "";
};

export default DayMealBreaks;
