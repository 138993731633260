import translate from "./translate";
import CustomFormScreen from "./CustomFormScreen";
import components from "./components";
import useFormState from "./useFormState";
import saveCustomForm from "./saveCustomForm";

const SavedCustomFormScreen = ({ action, method, data: { customFormId, disabled, name, schema } }) => {
  const { currentSchema, values, errors, handleControlChange, handleSubmit, isSubmitting } = useFormState({
    schema,
    onSubmit: ({ updatedSchema }) => {
      saveCustomForm({
        action,
        customFormId,
        method,
        schemaToSave: updatedSchema,
      });
    },
  });

  return (
    <CustomFormScreen
      components={components}
      disabled={disabled}
      errors={errors}
      infoMessage={disabled && translate("already_signed_text")}
      isSubmitting={isSubmitting}
      name={name}
      onControlChange={handleControlChange}
      onSubmit={handleSubmit}
      schema={currentSchema}
      submitButtonText={translate(disabled ? "continue" : "save")}
      values={values}
    />
  );
};

export default SavedCustomFormScreen;
