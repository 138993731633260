import Page from "components/Page";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardReaderErrorSvg from "./CardReaderErrorSvg";

const ProcessingErrorScreen = ({ title, subtitle, instructions, buttonText, onContinue }) => (
  <Page>
    <Page.Title>{title}</Page.Title>
    {subtitle && <Page.Subtitle>{subtitle}</Page.Subtitle>}

    <Page.Content display="flex" flexDirection="column" justifyContent="center" textAlign="center">
      <Box mb={2}>
        <CardReaderErrorSvg />
      </Box>
      {instructions && <Typography variant="body1">{instructions}</Typography>}
    </Page.Content>

    <Page.ButtonPrimary onClick={onContinue}>{buttonText}</Page.ButtonPrimary>
  </Page>
);

export default ProcessingErrorScreen;
