import { Formik } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import Page from "components/Page";
import FormikCheckboxLine from "components/FormikControls/FormikCheckboxLine";
import FormikSelect from "components/FormikControls/FormikSelect";
import InfoFields from "./InfoFields";
import LocationFields from "./LocationFields";
import t from "../translate";

const GuarantorForm = ({
  initialDay,
  initialMonth,
  initialValues,
  initialYear,
  isSubmitting,
  monthOptions,
  onSubmit,
  patientsFullAddress,
  relationshipOptions,
  relationshipSelfValue,
  stateOptions,
  validationSchema,
  yearOptions,
}) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
    {({ values }) => {
      const toggleGuarantorInfo =
        values.relationshipToPatient && values.relationshipToPatient !== relationshipSelfValue;

      return (
        <Page>
          <Page.Form formik />
          <Page.Title>{t("title")}</Page.Title>
          <Page.Subtitle>
            <OneColumnGrid>{t("financial_agreement_hint")}</OneColumnGrid>
          </Page.Subtitle>
          <Page.Content>
            <OneColumnGrid>
              <SpacedGrid>
                <Grid item xs={12}>
                  <FormikSelect
                    emptyOption={t("select_prompt")}
                    label={t("relationship_to_patient")}
                    name="relationshipToPatient"
                    options={relationshipOptions}
                    required
                  />
                </Grid>

                <InfoFields
                  initialDay={initialDay}
                  initialMonth={initialMonth}
                  initialYear={initialYear}
                  monthOptions={monthOptions}
                  toggle={toggleGuarantorInfo}
                  yearOptions={yearOptions}
                />

                <Grid item xs={12}>
                  <Box mt={3}>
                    <Grid alignItems="flex-start" container justifyContent="space-between">
                      <Grid item xs>
                        <Typography variant="body1">{t("billing_address")}</Typography>
                      </Grid>
                      <Grid item>
                        <FormikCheckboxLine
                          label={<Typography variant="caption">{t("address_same_as_patient")}</Typography>}
                          name="addressSameAsPatient"
                          value="true"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <LocationFields
                  patientsFullAddress={patientsFullAddress}
                  stateOptions={stateOptions}
                  toggle={values.addressSameAsPatient}
                />
              </SpacedGrid>
            </OneColumnGrid>
          </Page.Content>
          <Page.RequiredCopy />
          <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting}>
            {t("save_button")}
          </Page.ButtonPrimary>
        </Page>
      );
    }}
  </Formik>
);

export default GuarantorForm;
