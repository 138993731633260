import { useMemo } from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import FormField from "components/FormField";

const getOptionLabel = (option) => option.label;
const isOptionEqualToValue = (option, value) => option.value === value.value;
const renderInput = (params) => <TextField {...params} />;
const optionLabel = (value, options) => options.find((option) => option.value === value).label;

const SelectMultiple = ({
  error,
  fullWidth,
  label,
  onChange,
  options,
  required,
  values,
  placeholder,
  controlledError,
  ...rest
}) => {
  const handleChange = (_, newValues) => onChange(newValues.map((value) => value.value));
  const selectedOptions = useMemo(
    () => values.map((value) => ({ label: optionLabel(value, options), value })),
    [options, values],
  );

  return (
    <FormField error={error || controlledError} fullWidth={fullWidth} label={label} required={required} valid={!error}>
      <Autocomplete
        data-testid="autocomplete"
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple
        onChange={handleChange}
        options={options}
        renderInput={(params) => renderInput({ placeholder, error: error || controlledError, ...params })}
        value={selectedOptions}
        {...rest}
      />
    </FormField>
  );
};

SelectMultiple.propTypes = {
  controlledError: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
};

SelectMultiple.defaultProps = {
  controlledError: false,
  error: undefined,
  fullWidth: false,
  required: false,
  values: [],
};

export default SelectMultiple;
