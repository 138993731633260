import Box from "@mui/material/Box";
import useResponsiveSpacing from "hooks/useResponsiveSpacing";
import { useTheme } from "@mui/material";
import Result from "./Result";

const handleSelect = (result, onSelectedResult) => {
  onSelectedResult(result);
};

const ResultsGrid = ({ results, onSelectedResult }) => {
  const theme = useTheme();
  const gridGap = theme.spacing(useResponsiveSpacing());

  return (
    <Box
      display="grid"
      gap={gridGap}
      gridAutoRows="auto"
      gridTemplateColumns={{ xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
    >
      {results.map((result) => (
        <Box display="grid" key={result.id ? result.id : result.ncpdpid}>
          <Result onSelectClick={() => handleSelect(result, onSelectedResult)} result={result} />
        </Box>
      ))}
    </Box>
  );
};

export default ResultsGrid;
