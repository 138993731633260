import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import Form from "./Form";

const GeneralInfo = ({
  method,
  action,
  data: {
    maritalStatus,
    maritalStatusOptions,
    maritalStatusEnabled,
    preferredName,
    preferredNameEnabled,
    ssn,
    ssnEnabled,
    ssnReadOnly,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = (values) => {
    setSubmitting(true);
    requestToServer({
      path: action,
      method,
      body: transformKeysToSnakeCase({ generalInfo: values }),
    });
  };

  return (
    <Form
      isSubmitting={isSubmitting}
      maritalStatus={maritalStatus}
      maritalStatusEnabled={maritalStatusEnabled}
      maritalStatusOptions={maritalStatusOptions}
      onSubmit={handleSubmit}
      preferredName={preferredName}
      preferredNameEnabled={preferredNameEnabled}
      ssn={ssn}
      ssnEnabled={ssnEnabled}
      ssnReadOnly={ssnReadOnly}
    />
  );
};

export default GeneralInfo;
