import { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTranslation from "hooks/useTranslation";
import DayMealBreaks from "./DayMealBreaks";
import DayHours from "./DayHours";
import DayOfWeek from "./DayOfWeek";

const WorkingHours = ({ schedule }) => {
  const t = useTranslation("universal.search_results");
  const { hours: weekSchedule, mealBreak: constantMealBreak } = schedule;

  return (
    <>
      <Typography sx={{ fontWeight: "bold", pb: 1 }} variant="body1">
        {t("pharmacy_hours")}
      </Typography>
      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          rowGap: 1,
          gridTemplateColumns: "1fr max-content",
        }}
      >
        {weekSchedule.map((daySchedule) => (
          <Fragment key={daySchedule.days.join("_")}>
            <Typography variant="body2">
              <DayOfWeek daySchedule={daySchedule} />
            </Typography>
            <Typography variant="body2">
              <DayHours daySchedule={daySchedule} />
              <DayMealBreaks mealBreaks={daySchedule.mealBreak} showWeekMealBreaks={!!constantMealBreak} />
            </Typography>
          </Fragment>
        ))}
        {constantMealBreak && (
          <Typography sx={{ gridColumn: "span 2" }} variant="body2">
            {t("meal_break", { startTime: constantMealBreak.startTime, endTime: constantMealBreak.endTime })}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default WorkingHours;

WorkingHours.propTypes = {
  schedule: PropTypes.shape({
    hours: PropTypes.arrayOf(
      PropTypes.shape({
        close: PropTypes.string.isRequired,
        closedAllDay: PropTypes.bool.isRequired,
        days: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
        everyDay: PropTypes.bool.isRequired,
        mealBreak: PropTypes.arrayOf(
          PropTypes.shape({
            startTime: PropTypes.string.isRequired,
            endTime: PropTypes.string.isRequired,
          }),
        ),
        open: PropTypes.string.isRequired,
        twentyFourHours: PropTypes.bool,
      }),
    ).isRequired,
    mealBreak: PropTypes.shape({
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

WorkingHours.defaultProps = {};
