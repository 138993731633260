import Modal from "components/Modal";
import t from "components/payments/Summary/translate";

const CollectionsBalanceModal = (props) => (
  <Modal
    {...props}
    subtitle={t("collections_balance_modal.explanation")}
    title={t("collections_balance_modal.title")}
  />
);

export default CollectionsBalanceModal;
