import forEach from "lodash/forEach";
import isPlainObject from "lodash/isPlainObject";
import snakeCase from "lodash/snakeCase";

const transformKeysToSnakeCase = (object) => {
  if (isPlainObject(object)) {
    const result = {};

    forEach(object, (value, key) => {
      result[snakeCase(key)] = transformKeysToSnakeCase(value);
    });

    return result;
  }

  if (Array.isArray(object)) {
    return object.map(transformKeysToSnakeCase);
  }

  return object;
};

export default transformKeysToSnakeCase;
