import { useTheme } from "@mui/material/styles";
import Page from "components/Page";
import SignaturePad from "components/SignaturePad";
import useTranslation from "hooks/useTranslation";

const SignaturePadView = ({ description, drawingInstructions, isSubmitting, onContinue, onSignatureChange, title }) => {
  const t = useTranslation("universal.signature_form");
  const { signaturePadColumnConstraints } = useTheme();

  return (
    <Page>
      <Page.Layout columnConstraints={signaturePadColumnConstraints} />
      <Page.Header hideLogoutButton />
      <Page.Title>{title || t("title")}</Page.Title>
      <Page.Subtitle>{description || t("description")}</Page.Subtitle>

      <Page.Content>
        <SignaturePad
          displayOnly={isSubmitting}
          onSignatureChange={onSignatureChange}
          signature={drawingInstructions}
        />
      </Page.Content>
      {!!drawingInstructions.length && (
        <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} onClick={onContinue} />
      )}
    </Page>
  );
};

export default SignaturePadView;
