import { addMethod, setLocale, string } from "yup";
import getEnv from "utils/getEnv";
import initTranslationsHelper from "./initTranslationsHelper";

const regexes = getEnv("regexes") || {};
const t = initTranslationsHelper("shared.validation");

// Override email validation
if (regexes.email_regex) {
  addMethod(string, "email", function validateEmail(message) {
    return this.matches(regexes.email_regex, {
      excludeEmptyString: true,
      message: message || t("error_invalid"),
      name: "email",
    });
  });
}

setLocale({
  mixed: {
    required: t("error_required"),
    oneOf: t("error_one_of"),
  },
  string: {
    email: t("error_invalid"),
    max: t("string_max"),
    min: t("string_min"),
  },
  number: {
    max: t("number_max"),
    min: t("number_min"),
  },
  array: {
    min: t("array_min"),
  },
});
