import { useMemo } from "react";
import PropTypes from "prop-types";
import groupBy from "lodash/groupBy";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Page from "components/Page";
import ColumnConstraints from "components/Page/ColumnConstraints";
import initTranslationsHelper from "utils/initTranslationsHelper";
import QuestionsList from "./QuestionsList";
import QuestionsSection from "./QuestionsSection";
import { sectionsPropType, questionsPropType } from "../propTypes";

const t = initTranslationsHelper("universal.screeners.screen");

const Screen = ({
  description,
  errors,
  inputTypes,
  oneColumnLayout,
  isSubmitting,
  notes,
  onChange,
  onSkip,
  onSubmit,
  questions,
  readOnly,
  sections,
  title,
  values,
  warning,
}) => {
  const validSectionIds = sections ? sections.map((section) => section.id) : null;
  const sectionsWithOther = sections ? [...sections, { id: "other", title: t("other") }] : null;
  const questionsBySection = sections
    ? groupBy(questions, (question) => (validSectionIds.includes(question.sectionId) ? question.sectionId : "other"))
    : null;
  const { screenerColumnConstraints, defaultColumnConstraints } = useTheme();
  const columnConstraints = oneColumnLayout ? defaultColumnConstraints : screenerColumnConstraints;
  const alerts = useMemo(() => {
    const result = [];
    if (readOnly) result.push({ children: t("read_only_message"), severity: "info", variant: "filled" });
    if (warning) result.push({ children: warning, severity: "warning" });
    return result;
  }, [readOnly, warning]);

  return (
    <Page>
      <Page.Alert alerts={alerts} />
      <Page.Layout columnConstraints={columnConstraints} />
      <Page.Title>{title}</Page.Title>
      <Page.Subtitle>
        <ColumnConstraints constraints={columnConstraints}>{description}</ColumnConstraints>
      </Page.Subtitle>

      <Page.Content disableGutters>
        {sectionsWithOther ? (
          sectionsWithOther.map(
            (section) =>
              questionsBySection[section.id] && (
                <QuestionsSection
                  errors={errors}
                  inputTypes={inputTypes}
                  key={section.id}
                  onChange={onChange}
                  questions={questionsBySection[section.id]}
                  readOnly={readOnly}
                  section={section}
                  values={values}
                />
              ),
          )
        ) : (
          <QuestionsList
            errors={errors}
            inputTypes={inputTypes}
            onChange={onChange}
            questions={questions}
            readOnly={readOnly}
            values={values}
          />
        )}
      </Page.Content>

      {!readOnly && <Page.RequiredCopy />}

      <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} onClick={onSubmit}>
        {t("continue")}
      </Page.ButtonPrimary>

      {!!onSkip && !readOnly && (
        <Page.ButtonTertiary disabled={isSubmitting} onClick={onSkip}>
          {t("skip")}
        </Page.ButtonTertiary>
      )}
      {notes.length && (
        <Page.Note align="left" placement="bottom">
          <ColumnConstraints constraints={columnConstraints}>
            {notes.map((note) => (
              <Box pb={2}>{note}</Box>
            ))}
          </ColumnConstraints>
        </Page.Note>
      )}
    </Page>
  );
};

export default Screen;

Screen.propTypes = {
  description: PropTypes.string,

  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  inputTypes: PropTypes.shape({
    desktop: PropTypes.oneOf(["buttonGroup", "checkboxGroup", "number", "radio", "toggleQuestion"]),
    mobile: PropTypes.oneOf(["radio", "select"]),
  }),
  notes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  oneColumnLayout: PropTypes.bool,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  questions: questionsPropType.isRequired,
  readOnly: PropTypes.bool,
  sections: sectionsPropType,
  title: PropTypes.string.isRequired,

  values: PropTypes.object,
  warning: PropTypes.string,
};

Screen.defaultProps = {
  description: "",
  errors: {},
  inputTypes: undefined,
  isSubmitting: false,
  notes: [],
  oneColumnLayout: false,
  onSkip: undefined,
  readOnly: false,
  sections: undefined,
  values: {},
  warning: "",
};
