import { useState } from "react";
import useCounter from "hooks/useCounter";
import { SWIPE_DETECTION_TIMEOUT } from "hooks/cardReader/useSwipeDetection";
import { trackEvent } from "utils/EventsTracking";
import createRetailTransaction from "./createRetailTransaction";
import SwipeScreen from "./SwipeScreen";
import ProcessingErrorScreen from "./ProcessingErrorScreen";
import t from "../../translate";

const MAX_SWIPE_ATTEMPTS = 3;
const MAX_SWIPE_NOT_DETECTED_TIMEOUTS = 2;

const RetailTransaction = ({
  additionalData,
  amountToPay,
  onSuccessfulPayment,
  onUnrecoverableError,
  onSwipeNotDetected,
  processingMessage,
  processingTitle,
  submissionPath,
  subtitle,
  swipeDetectedEventName,
  swipeNotDetectedEventName,
  takingTooLongMessage,
  takingTooLongTitle,
  title,
}) => {
  const [error, setError] = useState("");
  const [noSwipeDetected, setNoSwipeDetected] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [swipeAttempts, incrementSwipeAttempts] = useCounter(1);
  const [swipeNotDetectedTimeouts, incrementSwipeNotDetectedTimeouts] = useCounter();

  const handleSwipeDetected = (swipeData) => {
    if (swipeDetectedEventName) {
      trackEvent(swipeDetectedEventName, {
        expected_swipe: true,
        card_reader_serial_number: swipeData.cardData.device_serial_number,
        ipad_id: swipeData.ipadId,
      });
    }

    setProcessing(true);
    createRetailTransaction(
      submissionPath,
      {
        amount: amountToPay,
        cardData: swipeData.cardData,
      },
      additionalData,
    ).then(async (response) => {
      setProcessing(false);
      switch (response.status) {
        case 200:
          onSuccessfulPayment();
          break;
        case 422: {
          const data = await response.json();

          if (data.error.recoverable && swipeAttempts < MAX_SWIPE_ATTEMPTS) {
            incrementSwipeAttempts();
            setError(data.error.text);
          } else {
            onUnrecoverableError();
          }
          break;
        }
        default:
          onUnrecoverableError();
          break;
      }
    });
  };

  const handleSwipeNotDetected = () => {
    if (swipeNotDetectedEventName) {
      trackEvent(swipeNotDetectedEventName, {
        timeout_used_in_ms: SWIPE_DETECTION_TIMEOUT,
      });
    }

    incrementSwipeNotDetectedTimeouts();
    setNoSwipeDetected(true);
  };

  if (error) {
    return (
      <ProcessingErrorScreen
        buttonText={t("tap_to_try_again")}
        onContinue={() => setError("")}
        subtitle={error}
        title={t("processing_error")}
      />
    );
  }

  if (noSwipeDetected) {
    const maxSwipeNotDetectedTimeoutsReached = swipeNotDetectedTimeouts >= MAX_SWIPE_NOT_DETECTED_TIMEOUTS;

    return (
      <ProcessingErrorScreen
        title={t("no_swipe_detected")}
        {...(maxSwipeNotDetectedTimeoutsReached
          ? {
              subtitle: t("no_swipe_detected_instructions"),
              onContinue: onSwipeNotDetected,
            }
          : {
              onContinue: () => setNoSwipeDetected(false),
              buttonText: t("tap_to_try_again"),
            })}
      />
    );
  }

  return (
    <SwipeScreen
      amountToPay={amountToPay}
      onSwipeDetected={handleSwipeDetected}
      onSwipeNotDetected={handleSwipeNotDetected}
      processing={processing}
      processingMessage={processingMessage}
      processingTitle={processingTitle}
      subtitle={subtitle}
      takingTooLongMessage={takingTooLongMessage}
      takingTooLongTitle={takingTooLongTitle}
      title={title}
    />
  );
};

export default RetailTransaction;
