import { useCallback } from "react";
import PropTypes from "prop-types";
import TextField, {
  propTypes as textFieldPropTypes,
  defaultProps as textFiledDefaultProps,
} from "components/TextField";

const MaskedTextField = ({ value, onChange, inputMask, supportedSymbols, ...props }) => {
  const maskValue = useCallback(buildMaskFunction(inputMask, supportedSymbols), [inputMask, supportedSymbols]);
  const onChangeWithUnmask = useCallback(
    (e) => {
      e.target.value = removeUnsupportedSymbols(e.target.value, supportedSymbols).slice(
        0,
        inputMask.match(/#/g).length,
      );
      onChange(e);
    },
    [inputMask, supportedSymbols, onChange],
  );

  return <TextField onChange={onChangeWithUnmask} value={maskValue(value)} {...props} />;
};

const buildMaskFunction = (inputMask, supportedSymbols) => {
  const maskParts = inputMask.match(/([^#]*#+)/g) || [];
  const maskRegExp = new RegExp(
    maskParts.reduce((result, maskPart) => `${result}([${supportedSymbols}]{0,${maskPart.match(/#/g).length}})`, ""),
    "",
  );

  return (value) => {
    if (value === null || value === undefined) return "";

    const sanitizedValue = removeUnsupportedSymbols(value, supportedSymbols);
    const valueParts = sanitizedValue.match(maskRegExp).slice(1);
    const maskedValue = valueParts.reduce(
      (result, part, i) => result + (part ? `${maskParts[i].replace(/#{1,}/, part)}` : ""),
      "",
    );

    return maskedValue;
  };
};

const removeUnsupportedSymbols = (value, supportedSymbols) => {
  const unsupportedSymbolsRegExp = new RegExp(`[^${supportedSymbols}]`, "g");

  return value.replace(unsupportedSymbolsRegExp, "");
};

MaskedTextField.propTypes = {
  ...textFieldPropTypes,
  inputMask: PropTypes.string.isRequired,
  supportedSymbols: PropTypes.string.isRequired,
};

MaskedTextField.defaultProps = {
  ...textFiledDefaultProps,
  inputMask: "",
  supportedSymbols: "0-9",
};

export default MaskedTextField;
