import ToggleOption from "components/Toggle/ToggleOption";
import FormField from "components/FormField";
import { Box } from "@mui/material";

const ToggleQuestionGroup = ({ error, onChange, question, readOnly, value }) => (
  <FormField centerErrorText error={error} fullWidth>
    <Box display="flex" justifyContent="space-between" pt={2}>
      {question.options.map((option) => (
        <ToggleOption
          active={option.value?.toString() === value?.toString()}
          disabled={readOnly}
          key={`option_${option.value}`}
          label={option.label}
          name={question.name}
          onChange={() => !readOnly && onChange(question.name, option)}
          value={option.value}
        />
      ))}
    </Box>
  </FormField>
);

export default ToggleQuestionGroup;
