import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import FormikTextField from "components/FormikControls/FormikTextField";
import Page from "components/Page";
import Grid from "@mui/material/Grid";

const ReceiptEmailScreen = ({
  cardSubtitle,
  cardTitle,
  emailFieldInitialValue,
  emailFieldLabel,
  isSubmitting,
  onSkip,
  onSubmit,
  primaryButtonText,
  secondaryButtonText,
  subtitle,
  title,
}) => (
  <Formik
    initialValues={{ email: emailFieldInitialValue }}
    onSubmit={(values) => onSubmit(values.email)}
    validationSchema={validationSchema}
  >
    <Page>
      <Page.Form formik />
      <Page.Title>{title}</Page.Title>
      <Page.Subtitle>{subtitle}</Page.Subtitle>
      <Page.Content>
        <Grid alignItems="center" container direction="column">
          <Grid item md={6} xs={12}>
            <Paper elevation={4}>
              <Box m={2}>
                <Box pb={4} pt={2} textAlign="center">
                  <Typography variant="h6">{cardTitle}</Typography>
                </Box>
                {cardSubtitle && (
                  <Box pb={2} textAlign="center">
                    <Typography variant="body2">{cardSubtitle}</Typography>
                  </Box>
                )}
                <Box pb={2}>
                  <FormikTextField fullWidth label={emailFieldLabel} name="email" required />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Page.Content>
      <Page.ButtonPrimary disabled={isSubmitting}>{primaryButtonText}</Page.ButtonPrimary>
      <Page.ButtonSecondary disabled={isSubmitting} onClick={onSkip}>
        {secondaryButtonText}
      </Page.ButtonSecondary>
    </Page>
  </Formik>
);

const validationSchema = Yup.object().shape({
  email: Yup.string().ensure().email().required(),
});

ReceiptEmailScreen.propTypes = {
  cardSubtitle: PropTypes.string,
  cardTitle: PropTypes.string.isRequired,
  emailFieldInitialValue: PropTypes.string,
  emailFieldLabel: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSkip: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ReceiptEmailScreen.defaultProps = {
  cardSubtitle: undefined,
  emailFieldInitialValue: "",
  subtitle: undefined,
};

export default ReceiptEmailScreen;
