import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Page from "components/Page";
import initTranslationsHelper from "utils/initTranslationsHelper";
import OneColumnGrid from "components/OneColumnGrid";
import SpacedGrid from "components/SpacedGrid";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikZipField from "components/FormikControls/FormikZipField";
import validationSchema from "./validationSchema";

const translate = initTranslationsHelper("universal.personal_information.location");

const LocationForm = ({ initialValues, isSubmitting, onSubmit, stateOptions }) => (
  <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)} validationSchema={validationSchema}>
    <Page>
      <Page.Form formik />
      <Page.Title>{translate("title")}</Page.Title>
      <Page.Content>
        <OneColumnGrid>
          <SpacedGrid>
            <Grid item xs={12}>
              <FormikTextField fullWidth label={translate("address1")} name="address1" required />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField fullWidth label={translate("address2")} name="address2" />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField fullWidth label={translate("city")} name="city" required />
            </Grid>
            <Grid item xs={6}>
              <FormikSelect emptyOption="" label={translate("state")} name="state" options={stateOptions} required />
            </Grid>
            <Grid item xs={6}>
              <FormikZipField fullWidth label={translate("zip")} name="zip" required />
            </Grid>
          </SpacedGrid>
        </OneColumnGrid>
      </Page.Content>
      <Page.RequiredCopy />
      <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting}>
        {translate("save_button")}
      </Page.ButtonPrimary>
    </Page>
  </Formik>
);

export default LocationForm;
