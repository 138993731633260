import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import View, { validationSchema } from "./View";

const Universal = ({ method, action, data: { address1, address2, city, state, stateOptions, zip } }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const showForm = !validationSchema.isValidSync({
    address1,
    address2,
    city,
    state,
    zip,
  });

  const submit = (data) => {
    setSubmitting(true);
    requestToServer({ body: transformKeysToSnakeCase(data), path: action, method });
  };

  const handleConfirm = () => submit({ quickConfirm: true });
  const handleSubmit = (values) => submit({ location: values });

  return (
    <View
      address1={address1}
      address2={address2}
      city={city}
      isSubmitting={isSubmitting}
      onConfirm={handleConfirm}
      onSubmit={handleSubmit}
      showForm={showForm}
      state={state}
      stateOptions={stateOptions}
      zip={zip}
    />
  );
};

export default Universal;
