import getEnv from "utils/getEnv";

const regexes = getEnv("regexes");

if (regexes) {
  Object.keys(regexes).map((key) => {
    if (typeof regexes[key] === "string") {
      if (regexes[key].charAt(0) === "/") {
        regexes[key] = new RegExp(regexes[key].slice(1, -1));
      }
      regexes[key] = new RegExp(regexes[key]);
    }
    return null;
  });
}

const validLength = (string) => regexes.nanp_valid_length.test(string);
const repeatedDigits = (string) => regexes.nanp_repeated_digits.test(string);
const matchesInvalidPattern = (string) => regexes.nanp_invalid_regex.test(string);

const isNanpPhoneNumberValid = (string) =>
  validLength(string) && !repeatedDigits(string) && !matchesInvalidPattern(string);

export default isNanpPhoneNumberValid;
