import { useFormikContext } from "formik";
import { useEffect } from "react";
import { trackEvent } from "utils/EventsTracking";

const FormikErrorTracker = ({ eventName }) => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const hasErrorsOnSubmission = isSubmitting && !isValidating && Object.keys(errors).length > 0;
    if (hasErrorsOnSubmission) trackEvent(eventName, { errors });
  }, [isSubmitting, isValidating, errors, eventName]);

  return null;
};

export default FormikErrorTracker;
