import PaymentForm from "components/payments/PaymentForm";
import t from "../../translate";

const ECommerceTransaction = ({
  amountToPay,
  patient,
  previsitToken,
  transactionToken,
  submissionPath,
  onSuccess,
  onUnrecoverableError,
  stateOptions,
}) => {
  const fullName = getFullName(patient);
  const address = getFullAddress(patient);
  const addressIncomplete = isAddressIncomplete(patient);

  return (
    <PaymentForm
      additionalData={{
        previsit_token: previsitToken,
        transaction_token: transactionToken,
      }}
      amountToPay={amountToPay}
      billingAddress={address}
      billingAddressIncomplete={addressIncomplete}
      billingZip={patient.zip}
      fullName={fullName}
      onSuccess={onSuccess}
      onUnrecoverableError={onUnrecoverableError}
      processingMessage={t("processing_description")}
      states={stateOptions}
      submitFormPath={submissionPath}
      takingTooLongMessage={t("taking_too_long_description")}
    />
  );
};

export default ECommerceTransaction;

const getFullName = (patient) => `${patient.firstName} ${patient.lastName}`;

const getFullAddress = ({ address1, address2, city, state, zip }) => {
  const address = [address1, address2, city, state].filter(Boolean).join(", ");

  return [address, zip].filter(Boolean).join(" ");
};

const isAddressIncomplete = ({ address1, city, state, zip }) => [address1, city, state, zip].some((field) => !field);
