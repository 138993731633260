import * as Yup from "yup";
import initTranslationsHelper from "utils/initTranslationsHelper";

const defaultTranslate = initTranslationsHelper("shared.validation");

const nameSchema = (translate) =>
  Yup.string()
    .max(100)
    .matches(/^[^,]*$/, (translate || defaultTranslate)("error_no_comma"));

export default nameSchema;
