import { useState } from "react";
import requestToServer from "utils/requestToServer";
import View from "./View";

const MoreInfo = ({ action, method, data: { referralSources } }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);

    requestToServer({
      path: action,
      method,
      body: { more_info: values },
    });
  };

  return <View isSubmitting={isSubmitting} onSubmit={handleSubmit} referralSources={referralSources} />;
};

export default MoreInfo;
