import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import QuickConfirm from "components/QuickConfirm";
import { maskValue } from "components/PhoneTextField";
import inferContactMethod, { CONTACT_METHODS } from "./inferContactMethod";
import t from "./translate";

const ContactInfoQuickConfirm = ({
  onConfirm,
  onUpdate,
  email,
  mobilePhone,
  homePhone,
  workPhone,
  preferredContactMethod,
  hideWorkPhone,
}) => {
  const theme = useTheme();
  const icon = theme.icons.contactInfo;
  const contactMethod = inferContactMethod(preferredContactMethod);

  const contactInfo = (
    <Box textAlign="center">
      <InfoRow value={email} />
      <InfoRow
        label={t("mobile_phone_label")}
        preferred={contactMethod === CONTACT_METHODS.mobilePhone}
        value={maskValue(mobilePhone)}
      />
      <InfoRow
        label={t("home_phone_label")}
        preferred={contactMethod === CONTACT_METHODS.homePhone}
        value={maskValue(homePhone)}
      />
      {hideWorkPhone || (
        <InfoRow
          label={t("work_phone")}
          preferred={contactMethod === CONTACT_METHODS.workPhone}
          value={maskValue(workPhone)}
        />
      )}
    </Box>
  );

  return (
    <QuickConfirm
      icon={icon}
      itemList={contactInfo}
      message={t("quick_confirm.message")}
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onUpdate}
      secondaryButtonText={t("quick_confirm.update")}
    />
  );
};

export default ContactInfoQuickConfirm;

const InfoRow = ({ value, label, preferred }) => (
  <Box mb={1}>
    <Grid container justifyContent="center" spacing={1}>
      {label && (
        <Grid item>
          <Label>{`${label}: `}</Label>
        </Grid>
      )}
      <Grid item>
        <Value>{value}</Value>
      </Grid>
      {preferred && (
        <Grid item>
          <Preferred>{` (${t("quick_confirm.preferred")})`}</Preferred>
        </Grid>
      )}
    </Grid>
  </Box>
);

const Value = ({ children }) => (
  <Typography color="textPrimary" component="span">
    {children}
  </Typography>
);

const Label = ({ children }) => (
  <Typography component="span">
    <Box component="strong" fontWeight="fontWeightBold">
      {children}
    </Box>
  </Typography>
);

const Preferred = ({ children }) => (
  <Typography component="span" sx={{ color: "var(--palette-text-tertiary)" }}>
    {children}
  </Typography>
);
