import Page from "components/Page";
import Divider from "@mui/material/Divider";
import { Box, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import initTranslationsHelper from "utils/initTranslationsHelper";
import {
  translateRelationshipOption,
  translateMaritalStatusOption,
  translateRaceOption,
  translateOccupationOption,
  translateEthnicityOption,
  translateLanguageOption,
} from "utils/translateOptions";

const translate = initTranslationsHelper("universal.personal_information.show");

const View = ({
  isSubmitting,
  onConfirm,
  confirmPath,
  emergencyContact: {
    emergencyContactEnabled,
    contactName,
    contactPrimaryPhone,
    contactMobilePhone,
    contactHomePhone,
    contactRelationship,
    emergencyContactEditPath,
  },
  generalInfo: {
    generalInfoEnabled,
    showPreferredName,
    preferredName,
    ofEmancipationAge,
    maritalStatus,
    showSSN,
    ssn,
    generalInfoEditPath,
  },
  demographics: {
    demographicsEnabled,
    freeTextOccupation,
    occupation,
    race,
    language,
    ethnicity,
    demographicsEditPath,
  },
  genderAndSexuality: {
    genderAndSexualityEnabled,
    assignedSex,
    genderIdentity,
    preferredPronouns,
    sexualOrientation,
    altFirstName,
    genderAndSexualityEditPath,
  },
  nextOfKin: { nextOfKinEnabled, fullName, phone, relationship, nextOfKinEditPath },
}) => (
  <Page>
    <Page.Title>{translate("confirm_personal_info")}</Page.Title>
    <Page.Content>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          {emergencyContactEnabled && (
            <Section
              path={emergencyContactEditPath}
              rows={[
                { label: translate("name"), value: contactName },
                { label: translate("primary"), value: contactPrimaryPhone, show: !!contactPrimaryPhone },
                { label: translate("mobile"), value: contactMobilePhone, show: !!contactMobilePhone },
                { label: translate("home"), value: contactHomePhone, show: !!contactHomePhone },
                {
                  label: translate("relationship"),
                  value: translateRelationshipOption(contactRelationship).label,
                  show: !!contactRelationship,
                },
              ]}
              title={translate("emergency_contact")}
            />
          )}

          {generalInfoEnabled && (
            <Section
              path={generalInfoEditPath}
              rows={[
                { label: translate("preferred_name"), value: preferredName, show: showPreferredName },
                {
                  label: translate("marital_status"),
                  value: translateMaritalStatusOption(maritalStatus).label,
                  show: ofEmancipationAge,
                },
                { label: translate("ssn"), value: ssn, show: showSSN },
              ]}
              title={translate("general_info")}
            />
          )}

          {demographicsEnabled && (
            <Section
              path={demographicsEditPath}
              rows={[
                {
                  label: translate("occupation"),
                  value: freeTextOccupation ? occupation : translateOccupationOption(occupation).label,
                },
                {
                  label: translate("race"),
                  value: race
                    .map(translateRaceOption)
                    .map((option) => option.label)
                    .join(", "),
                },
                { label: translate("language"), value: translateLanguageOption(language).label },
                {
                  label: translate("ethnicity"),
                  value: ethnicity
                    .map(translateEthnicityOption)
                    .map((option) => option.label)
                    .join(", "),
                },
              ]}
              title={translate("demographics")}
            />
          )}

          {genderAndSexualityEnabled && (
            <Section
              path={genderAndSexualityEditPath}
              rows={[
                { label: translate("assigned_sex"), value: assignedSex, show: !!assignedSex },
                { label: translate("gender_identity"), value: genderIdentity, show: !!genderIdentity },
                { label: translate("preferred_pronouns"), value: preferredPronouns, show: !!preferredPronouns },
                { label: translate("sexual_orientation"), value: sexualOrientation, show: !!sexualOrientation },
                { label: translate("alt_first_name"), value: altFirstName, show: !!altFirstName },
              ]}
              title={translate("gender_and_sexuality")}
            />
          )}

          {nextOfKinEnabled && (
            <Section
              path={nextOfKinEditPath}
              rows={[
                { label: translate("name"), value: fullName },
                { label: translate("phone"), value: phone },
                { label: translate("relationship"), value: relationship },
              ]}
              title={translate("next_of_kin")}
            />
          )}
        </Grid>
      </Grid>
    </Page.Content>
    <Page.ButtonPrimary disabled={isSubmitting} href={confirmPath} loading={isSubmitting} onClick={onConfirm}>
      {translate("confirm")}
    </Page.ButtonPrimary>
  </Page>
);

const Section = ({ title, path, rows }) => (
  <Box mt={4}>
    <SectionHeader path={path} title={title} />
    {rows
      .filter((row) => row.show !== false)
      .map((row) => (
        <SectionRow key={row.label} label={row.label} value={row.value} />
      ))}
  </Box>
);

const SectionHeader = ({ title, path }) => (
  <Box alignItems="flex-end" display="flex" justifyContent="space-between" mb={4}>
    <Typography variant="h6">{title}</Typography>
    <Link href={path} variant="body1">
      {translate("edit")}
    </Link>
  </Box>
);

const SectionRow = ({ label, value }) => (
  <>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body1">{label}</Typography>
      <Typography align="right" color="textPrimary" variant="body1">
        {value}
      </Typography>
    </Box>
    <Box mb={2} mt={2}>
      <Divider />
    </Box>
  </>
);

export default View;
