import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import initTranslationsHelper from "utils/initTranslationsHelper";
import SwipeCreditCardSvg from "./SwipeCreditCardSvg";

const t = initTranslationsHelper("universal.universal_payment");

const SwipeCreditCard = () => (
  <Grid container justifyContent="center">
    <Grid item xs={12}>
      <Box textAlign="center">
        <SwipeCreditCardSvg />
        <Typography variant="body1">{t("swipe_card_instructions")}</Typography>
      </Box>
    </Grid>
  </Grid>
);

export default SwipeCreditCard;
