import { Children } from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

const StripedList = ({ children, odd }) => (
  <ul
    className={clsx(styles.list, {
      [styles.listEven]: !odd,
      [styles.listOdd]: odd,
    })}
  >
    {Children.map(children.filter(Boolean), (child) => (
      <li>{child}</li>
    ))}
  </ul>
);

StripedList.defaultProps = {
  odd: false,
};

export default StripedList;
