import React from "react";
import Log from "utils/logging";
import useTranslation from "hooks/useTranslation";
import UnexpectedError from "components/ErrorScreen/UnexpectedError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Log.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    const handleContinueClick = () => {
      const { onContinueClick } = this.props;
      if (onContinueClick) {
        onContinueClick();
      }

      this.setState({ hasError: false });
    };

    if (hasError) {
      const { description, hideContinue, title } = this.props;

      return (
        <ErrorScreen description={description} onContinueClick={!hideContinue && handleContinueClick} title={title} />
      );
    }

    return children;
  }
}

const ErrorScreen = ({ description, onContinueClick, title }) => {
  const t = useTranslation("universal.error_screen");

  return (
    <UnexpectedError
      description={description || t("instructions")}
      onActionClick={onContinueClick}
      title={title || t("header")}
    />
  );
};

export default ErrorBoundary;
