import { useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";

const PayIcon = () => {
  const {
    palette: {
      primary: { main: primaryColor },
    },
  } = useTheme();

  return (
    <SvgIcon color="primary" sx={{ height: 112, width: 113, fill: "none" }} viewBox="0 0 112 113">
      <path
        d="M110 56.5C110 86.3234 85.8234 110.5 56 110.5C26.1766 110.5 2 86.3234 2 56.5C2 26.6766 26.1766 2.5 56 2.5C85.8234 2.5 110 26.6766 110 56.5Z"
        stroke={primaryColor}
        strokeWidth="2.8"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M72.4966 34.4028L70.4444 37.5495C66.3856 34.836 61.5515 33.5363 57.2874 33.5363C50.1731 33.5363 44.883 37.1619 44.883 43.2957C44.883 57.5927 73.5683 50.1136 73.5683 68.1274C73.5683 77.0659 66.5224 81.672 57.9715 82.3104V89.516H54.0951V82.3332C47.9157 81.9456 42.7852 79.6198 39 76.7923L41.0294 73.6227C44.9514 76.359 49.8311 78.4568 55.8053 78.4568C64.1509 78.4568 69.1674 74.398 69.1674 68.4466C69.1674 54.218 40.505 61.4463 40.505 43.6605C40.505 35.7253 46.2283 30.572 54.5968 29.7511V22.5H58.4732V29.6599C63.4897 29.9335 68.4834 31.6209 72.4966 34.4028Z"
        fill={primaryColor}
        xmlns="http://www.w3.org/2000/svg"
      />
    </SvgIcon>
  );
};

export default PayIcon;
