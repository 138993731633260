export default function initFBSelect() {
  if (!window.fbControls) window.fbControls = [];
  window.fbControls.push((controlClass) => {
    const SELECT_WRAPPER_CLASS = "styled-select";

    class Select extends controlClass {
      build() {
        const options = [];
        const { values, value, type, ...data } = this.config;
        let { placeholder } = this.config;

        if (data.multiple) {
          data.name += "[]";
        }

        delete data.title;

        if (values) {
          placeholder = placeholder || " ";
          options.push(
            this.markup("option", placeholder, {
              value: "",
              disabled: null,
              selected: null,
            }),
          );

          // process the rest of the options
          for (let i = 0; i < values.length; i++) {
            let option = values[i];
            if (typeof option === "string") {
              option = { label: option, value: option };
            }
            const { label = "", ...optionAttrs } = option;
            optionAttrs.id = `${data.id}-${i}`;

            // don't select this option if a placeholder is defined
            if (!optionAttrs.selected || placeholder) {
              delete optionAttrs.selected;
            }

            // if a value is defined at select level, select this attribute
            if (typeof value !== "undefined" && optionAttrs.value === value) {
              optionAttrs.selected = true;
            }

            const optionItem = this.markup("option", document.createTextNode(label), optionAttrs);
            options.push(optionItem);
          }
        }

        const selectInput = this.markup(type, options, data);
        return this.markup("div", selectInput, {
          className: SELECT_WRAPPER_CLASS,
        });
      }
    }

    // register this control for the following types & text subtypes
    controlClass.register("select", Select);
    return Select;
  });
}
