import { useState } from "react";
import PropTypes from "prop-types";
import ContactInfoForm from "./ContactInfoForm";
import ContactInfoQuickConfirm from "./ContactInfoQuickConfirm";
import getValidationSchema from "./getValidationSchema";

const ContactInfo = ({
  consentsToText,
  editEmailModal,
  email,
  emailConfirmationEnabled,
  emailProvided,
  hasNonIntegratedSmsConsent,
  hideEmail,
  hideWorkPhone,
  homePhone,
  isSubmitting,
  mobilePhone,
  officeContactPhone,
  onConfirm,
  onSubmit,
  preferredContactMethod = null,
  preferredContactMethodOptions,
  readOnlyEmail,
  readOnlyPhoneFields,
  workPhone,
}) => {
  const consentsToTextProvided = typeof consentsToText !== "undefined";
  const validationSchema = getValidationSchema({
    consentsToTextProvided,
    email,
    preferredContactMethodOptions,
    readOnlyEmail,
    hideEmail,
    hideWorkPhone,
    emailConfirmationEnabled,
    readOnlyPhoneFields,
  });

  const nonIntegratedSmsConsentAndNotConsented = hasNonIntegratedSmsConsent && !consentsToText;

  const [showForm, setShowForm] = useState(
    () =>
      !validationSchema.isValidSync({
        email,
        emailProvided,
        mobilePhone,
        homePhone,
        workPhone,
        preferredContactMethod,
      }) ||
      !emailProvided ||
      nonIntegratedSmsConsentAndNotConsented,
  );

  const handleUpdate = () => {
    setShowForm(true);
  };

  return showForm ? (
    <ContactInfoForm
      consentsToText={consentsToText}
      consentsToTextProvided={consentsToTextProvided}
      editEmailModal={editEmailModal}
      email={email}
      emailConfirmationEnabled={emailConfirmationEnabled}
      emailProvided={emailProvided}
      hideEmail={hideEmail}
      hideWorkPhone={hideWorkPhone}
      homePhone={homePhone}
      isSubmitting={isSubmitting}
      mobilePhone={mobilePhone}
      officeContactPhone={officeContactPhone}
      onSubmit={onSubmit}
      preferredContactMethod={preferredContactMethod}
      preferredContactMethodOptions={preferredContactMethodOptions}
      readOnlyEmail={readOnlyEmail}
      readOnlyPhoneFields={readOnlyPhoneFields}
      validationSchema={validationSchema}
      workPhone={workPhone}
    />
  ) : (
    <ContactInfoQuickConfirm
      email={email}
      hideWorkPhone={hideWorkPhone}
      homePhone={homePhone}
      mobilePhone={mobilePhone}
      onConfirm={onConfirm}
      onUpdate={handleUpdate}
      preferredContactMethod={preferredContactMethod}
      workPhone={workPhone}
    />
  );
};

ContactInfo.propTypes = {
  consentsToText: PropTypes.bool,
  editEmailModal: PropTypes.bool,
  email: PropTypes.string,
  emailConfirmationEnabled: PropTypes.bool,
  emailProvided: PropTypes.bool.isRequired,
  hasNonIntegratedSmsConsent: PropTypes.bool,
  hideEmail: PropTypes.bool,
  hideWorkPhone: PropTypes.bool,
  homePhone: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  mobilePhone: PropTypes.string,
  officeContactPhone: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  preferredContactMethod: PropTypes.string,
  preferredContactMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  readOnlyEmail: PropTypes.bool,
  readOnlyPhoneFields: PropTypes.bool,
  workPhone: PropTypes.string,
};

ContactInfo.defaultProps = {
  consentsToText: undefined,
  editEmailModal: false,
  email: "",
  emailConfirmationEnabled: true,
  hasNonIntegratedSmsConsent: false,
  hideEmail: false,
  hideWorkPhone: false,
  homePhone: "",
  mobilePhone: "",
  officeContactPhone: "",
  preferredContactMethod: "HOMEPHONE",
  preferredContactMethodOptions: undefined,
  readOnlyEmail: false,
  readOnlyPhoneFields: false,
  workPhone: "",
};

export default ContactInfo;
