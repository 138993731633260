import omit from "lodash/omit";

export default function initFBDivider() {
  if (!window.fbControls) window.fbControls = [];
  window.fbControls.push((controlClass) => {
    /**
     * Divider class
     */
    class Divider extends controlClass {
      static get definition() {
        return {
          icon: "&#9778;",
          i18n: {
            default: "Section Divider",
          },
        };
      }

      build() {
        const attrs = omit(this.config, ["value"]);
        this.field = this.markup("hr", null, attrs);

        return this.field;
      }
    }

    controlClass.register("divider", Divider);
    return Divider;
  });
}
