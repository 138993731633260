import { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import SpacedGrid from "components/SpacedGrid";
import Modal from "components/Modal";
import isNil from "lodash/isNil";
import Page from "components/Page";
import TextField from "components/TextField";
import Paragraph from "components/Typography/Paragraph";
import RadioButtonGroup from "components/RadioButtonGroup";
import useTranslation from "hooks/useTranslation";
import { Link, Typography, Box } from "@mui/material";

const View = ({ disabled, errors, isSubmitting, onContinue, onNJIISChange, onSkip, patientAnswer }) => {
  const t = useTranslation("universal.nj_immunization_consents");
  const [consentToParticipateModalOpen, setConsentToParticipateModalOpen] = useState(false);

  const njiisOptions = useMemo(
    () => [
      { label: t("yes"), name: "njiis_yes", value: "true" },
      { label: t("no"), name: "njiis_no", value: "false" },
    ],
    [t],
  );

  const isContinueDisabled = () =>
    isNil(patientAnswer.patientGivesNJIISConsent) ||
    !patientAnswer.countryOfBirth ||
    !patientAnswer.nameOfPrimaryHealthCareProvider;

  const submitButtonText = () => {
    if (disabled) return t("continue");

    return t("confirm_and_sign");
  };

  const handleNJIISChange = (fieldName, value) => {
    onNJIISChange({ ...patientAnswer, [fieldName]: value });
  };

  return (
    <>
      <Page>
        <Page.Alert alerts={errors} />
        {disabled && <Page.Alert severity="info">{t("already_signed_text")}</Page.Alert>}
        <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />
        <Page.Title>{t("title")}</Page.Title>
        <Page.Content>
          <Typography sx={{ pb: 3 }} variant="body1">
            {t("description")}
          </Typography>
          <SpacedGrid>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                fullWidth
                label={t("patient_immunization_history.country_of_birth")}
                onChange={(e) => handleNJIISChange("countryOfBirth", e.target.value)}
                required
                value={patientAnswer.countryOfBirth}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                fullWidth
                label={t("patient_immunization_history.name_of_primary_health_care_provider")}
                onChange={(e) => handleNJIISChange("nameOfPrimaryHealthCareProvider", e.target.value)}
                required
                value={patientAnswer.nameOfPrimaryHealthCareProvider}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButtonGroup
                disabled={disabled}
                label={t("njiis_label")}
                onChange={(e) => handleNJIISChange("patientGivesNJIISConsent", JSON.parse(e.target.value))}
                options={njiisOptions}
                required
                value={`${patientAnswer.patientGivesNJIISConsent}`}
              />
            </Grid>
          </SpacedGrid>
        </Page.Content>
        <Page.Note>
          <Box>{t("consent_to_participate_note")}</Box>
          <Link onClick={() => setConsentToParticipateModalOpen(true)}>{`${t("consent_to_participate_title")}.`}</Link>
        </Page.Note>
        <Page.ButtonPrimary
          disabled={isContinueDisabled() || isSubmitting}
          loading={isSubmitting}
          onClick={disabled ? onSkip : onContinue}
        >
          {submitButtonText()}
        </Page.ButtonPrimary>
        {!disabled && (
          <Page.ButtonTertiary disabled={isSubmitting} loading={isSubmitting} onClick={onSkip}>
            {t("skip")}
          </Page.ButtonTertiary>
        )}
      </Page>
      <Modal
        doneButtonText={t("back")}
        onDoneButtonClick={() => setConsentToParticipateModalOpen(false)}
        open={consentToParticipateModalOpen}
        subtitle={
          <Box>
            <Box>{t("consent_to_participate_subtitle_1")}</Box>
            <Box>{t("consent_to_participate_subtitle_2")}</Box>
            <Box>{t("consent_to_participate_subtitle_3")}</Box>
            <Box>{t("consent_to_participate_subtitle_4")}</Box>
            <Box>{t("consent_to_participate_subtitle_5")}</Box>
          </Box>
        }
        title={t("consent_to_participate_title")}
        wide
      >
        <Paragraph>{t("consent_to_participate_para_1")}</Paragraph>
        <Paragraph>{t("consent_to_participate_para_2")}</Paragraph>
        <Paragraph>{t("consent_to_participate_para_3")}</Paragraph>
        <Paragraph>{t("consent_to_participate_para_4")}</Paragraph>
      </Modal>
    </>
  );
};

export default View;
