import { useState } from "react";
import SignatureForm from "previsit/SignatureForm";
import useTranslation from "hooks/useTranslation";
import requestToServer from "utils/requestToServer";
import CustomFormScreen from "./CustomFormScreen";
import components from "./components";
import useFormState from "./useFormState";
import getRenderedFormFromSchema from "./getRenderedFormFromSchema";

const SignedCustomConsentFormScreen = ({
  action,
  method,
  data: { caregiverAttributes, caregiverSignatureEnabled, customFormId, disabled, initialSignature, name, schema },
}) => {
  const [showSignature, setShowSignature] = useState(false);
  const [schemaToSave, setSchemaToSave] = useState(schema);
  const [isSubmitting, setSubmitting] = useState(false);
  const t = useTranslation("universal.custom_forms");

  const {
    currentSchema,
    values,
    errors,
    handleControlChange,
    handleSubmit,
    isSubmitting: formikIsSubmitting,
  } = useFormState({
    schema,
    onSubmit: ({ updatedSchema }) => {
      if (disabled) {
        submitData();
      } else {
        setSchemaToSave(updatedSchema);
        setShowSignature(true);
      }
    },
  });

  const submitData = (signatureData) => {
    setSubmitting(true);
    requestToServer({
      path: action,
      method,
      body: {
        custom_form_id: customFormId,
        signed_custom_consent: {
          consent_form: getRenderedFormFromSchema(schemaToSave),
          json_data: JSON.stringify(schemaToSave.controls),
          ...signatureData,
        },
      },
    });
  };

  return (
    <>
      {!showSignature && (
        <CustomFormScreen
          components={components}
          disabled={disabled}
          errors={errors}
          infoMessage={disabled && t("already_signed_text")}
          isSubmitting={formikIsSubmitting}
          key="customFormScreen"
          name={name}
          onControlChange={handleControlChange}
          onSubmit={handleSubmit}
          schema={currentSchema}
          submitButtonText={t("continue")}
          values={values}
        />
      )}
      {showSignature && (
        <SignatureForm
          caregiverAttributes={caregiverAttributes}
          caregiverSignatureEnabled={caregiverSignatureEnabled}
          initialDrawingInstructions={initialSignature}
          isSubmitting={isSubmitting}
          key="signatureFormScreen"
          onSave={submitData}
        />
      )}
    </>
  );
};

export default SignedCustomConsentFormScreen;
