import { useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import requestToServer from "utils/requestToServer";
import { PROVIDER_NAME_VALUE } from "components/PCPAndPharmacy/constants";
import QuickConfirmScreen from "./screens/QuickConfirmScreen";
import SearchFormScreen from "./screens/SearchFormScreen";
import SearchResultsScreen from "./screens/SearchResultsScreen";

const PrimaryCareProvider = ({
  method,
  action,
  data: { provider = {}, searchPath, stateOptions, paginationEnabled },
}) => {
  const [loading, setLoading] = useState(false);
  const pcpOnFile = useMemo(() => !isEmpty(provider), [provider]);
  const [screen, setScreen] = useState(pcpOnFile ? QuickConfirmScreen.name : SearchFormScreen.name);
  const [searchResults, setSearchResults] = useState(null);
  const [searchParams, setSearchParams] = useState({
    city: "",
    firstNameOrGroupName: "",
    lastName: "",
    locationSearchBy: "",
    nameSearchBy: PROVIDER_NAME_VALUE,
    state: "",
    zip: "",
  });
  const { address1, address2, city, name, phone, state, zip } = provider;

  const submit = (values) => {
    setLoading(true);
    requestToServer({ path: action, method, body: values });
  };
  const handleNoPcp = () => submit({ no_pcp: true });
  const handleConfirm = () => submit({ quick_confirm: true });
  const handleSkip = () => submit({ skip: true });
  const handleSearch = (values) => {
    setSearchParams({ ...values, title: `${values.firstNameOrGroupName} ${values.lastName}` });
    setLoading(true);
    requestToServer({ path: `${searchPath}?${new URLSearchParams(buildSearchParams(values))}` })
      .then((response) => response.json())
      .then((results) => results.map((result) => ({ ...result, id: result.clinicalproviderid, title: result.name })))
      .then(setSearchResults)
      .then(() => setLoading(false))
      .then(() => setScreen(SearchResultsScreen.name));
  };
  const handleNotFound = () => submit({ pcp_not_found: true });
  const handleSelect = (selectedProvider) => submit({ provider_id: selectedProvider.clinicalproviderid });

  switch (screen) {
    case QuickConfirmScreen.name:
      return (
        <QuickConfirmScreen
          address1={address1}
          address2={address2}
          city={city}
          name={name}
          onNoChange={handleConfirm}
          onNoPcp={handleNoPcp}
          onUpdate={() => setScreen(SearchFormScreen.name)}
          pcpOnFile={pcpOnFile}
          phone={phone}
          state={state}
          zip={zip}
        />
      );
    case SearchFormScreen.name:
      return (
        <SearchFormScreen
          initialValues={searchParams}
          loading={loading}
          onSearch={handleSearch}
          onSkip={handleSkip}
          stateOptions={stateOptions}
        />
      );
    case SearchResultsScreen.name:
      return (
        <SearchResultsScreen
          loading={loading}
          onEdit={() => setScreen(SearchFormScreen.name)}
          onNotFound={handleNotFound}
          onSelect={handleSelect}
          paginationEnabled={paginationEnabled}
          results={searchResults}
          searchParams={searchParams}
        />
      );
    default:
      return null;
  }
};

const buildSearchParams = ({ city, firstNameOrGroupName, lastName, state, zip, nameSearchBy }) => {
  const locationParams = { city, state, zip };

  if (nameSearchBy === PROVIDER_NAME_VALUE) {
    return {
      ...locationParams,
      first_name: firstNameOrGroupName,
      last_name: lastName,
    };
  }

  return {
    ...locationParams,
    group_or_office_name: firstNameOrGroupName,
  };
};

export default PrimaryCareProvider;
