import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import View from "./View";

const NextOfKin = ({ action, method, data: { nextOfKin, relationshipOptions } }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);
    requestToServer({
      path: action,
      method,
      body: transformKeysToSnakeCase({
        nextOfKin: values,
      }),
    });
  };

  return (
    <View
      isSubmitting={isSubmitting}
      nextOfKin={nextOfKin}
      onSubmit={handleSubmit}
      relationshipOptions={relationshipOptions}
    />
  );
};

export default NextOfKin;
