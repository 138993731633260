import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const dateMapper = {
  fromISODate: (isoDate) => {
    const date = dayjs.utc(isoDate);

    if (!date.isValid()) return ["", "", ""];

    return [date.year(), date.month() + 1, date.date()].map(String);
  },
  toIsoDate: (year, month, day) => {
    const date = dayjs.utc([year, month, day].join("-"), "YYYY-M-D");

    if (!date.isValid()) return "";

    return date.format("YYYY-MM-DD");
  },
};

export default dateMapper;
