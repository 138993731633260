import * as yup from "yup";

const removeValidation = (validations, enabledField) => {
  const field = enabledField.replace(/Enabled/, "");
  delete validations[field];
};

const getValidationSchema = (required, additionalGenderRegexp, somethingElseRegexp, translate, enabledData) => {
  const validations = required
    ? {
        assignedSex: yup.string().required(translate("error_one_of")),
        genderIdentity: yup.string().required(translate("error_one_of")),
        genderIdentityOther: yup.string().when("genderIdentity", {
          is: (genderIdentity) => additionalGenderRegexp.test(genderIdentity),
          then: yup.string().max(20, translate("string_max")).required(translate("error_required")),
          otherwise: yup.string().max(20, translate("string_max")).nullable(),
        }),
        preferredPronouns: yup.string().required(translate("error_one_of")),
        sexualOrientation: yup.string().required(translate("error_one_of")),
        sexualOrientationOther: yup.string().when("sexualOrientation", {
          is: (sexualOrientation) => somethingElseRegexp.test(sexualOrientation),
          then: yup.string().max(20, translate("string_max")).required(translate("error_required")),
          otherwise: yup.string().max(20, translate("string_max")).nullable(),
        }),
        altFirstName: yup.string().max(35, translate("string_max")).required(translate("error_required")),
      }
    : {
        assignedSex: yup.string().nullable(),
        genderIdentity: yup.string().nullable(),
        genderIdentityOther: yup.string().when("genderIdentity", {
          is: (genderIdentity) => additionalGenderRegexp.test(genderIdentity),
          then: yup.string().max(20, translate("string_max")).required(translate("error_required")),
          otherwise: yup.string().max(20, translate("string_max")).nullable(),
        }),
        preferredPronouns: yup.string().nullable(),
        sexualOrientation: yup.string().nullable(),
        sexualOrientationOther: yup.string().when("sexualOrientation", {
          is: (sexualOrientation) => somethingElseRegexp.test(sexualOrientation),
          then: yup.string().max(20, translate("string_max")).required(translate("error_required")),
          otherwise: yup.string().max(20, translate("string_max")).nullable(),
        }),
        altFirstName: yup.string().max(35, translate("string_max")).nullable(),
      };

  // Not all fields are supported for each EHR, so we remove the ones which are not enabled.
  Object.keys(enabledData).forEach((enabledField) => {
    if (!enabledData[enabledField]) removeValidation(validations, enabledField);
  });

  return yup.object(validations);
};

export default getValidationSchema;
