import Typography from "@mui/material/Typography";
import FormikRadioButtonGroup from "components/FormikControls/FormikRadioButtonGroup";
import { useField } from "formik";
import Grid from "@mui/material/Grid";
import FormikZipField from "components/FormikControls/FormikZipField";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikSelect from "components/FormikControls/FormikSelect";
import SpacedGrid from "components/SpacedGrid";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { CITY_AND_STATE_VALUE, ZIP_VALUE } from "components/PCPAndPharmacy/constants";
import LocationFieldsResetter from "components/PCPAndPharmacy/LocationFieldsResetter";
import t from "../../translate";

const LocationSection = ({ stateOptions }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6">{t("pharmacy_location")}</Typography>
      </Box>
      <FormikRadioButtonGroup
        inline={smUp}
        label={t("search_by")}
        name="locationSearchBy"
        options={[
          { label: t("zip_code"), value: ZIP_VALUE },
          { label: t("city_and_state"), value: CITY_AND_STATE_VALUE },
        ]}
        required
      />
      <Box mt={2}>
        <SearchByFields stateOptions={stateOptions} />
      </Box>
      <LocationFieldsResetter />
    </>
  );
};

export default LocationSection;

const SearchByFields = ({ stateOptions }) => {
  const [{ value: locationSearchBy }] = useField("locationSearchBy");
  if (!locationSearchBy) return null;

  return (
    <SpacedGrid>
      {locationSearchBy === ZIP_VALUE ? (
        <Grid item xs={12}>
          <FormikZipField fullWidth label={t("zip")} name="zip" required />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <FormikTextField fullWidth id="city" label={t("city")} name="city" required />
          </Grid>
          <Grid item xs={6}>
            <FormikSelect id="state" label={t("state")} name="state" options={stateOptions} required />
          </Grid>
        </>
      )}
    </SpacedGrid>
  );
};
